.Type_dark__2cSKZ {
  color: #a8a8a8;
}

.Type_disambiguation__1Ed6U {
  font-feature-settings: "ss02" 1;
}

.Type_text-small__SHvhp {
  font-size: 0.75rem;
  line-height: 1.125rem;
  letter-spacing: 0.14px;
}
.Type_text-regular__2NG8W {
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.16px;
}
.Type_text-large__7NXjT {
  font-size: 1rem;
  line-height: 1.375rem;
  letter-spacing: 0.18px;
}

.Type_h1__2DT14 {
  font-size: 3.125rem;
  line-height: 3.625rem;
  font-weight: 500;
  letter-spacing: -0.75px;
}

.Type_h2__2OXPK {
  font-size: 2.625rem;
  line-height: 3.125rem;
  font-weight: 500;
  letter-spacing: -0.5px;
}

.Type_h3__3-DyG {
  font-size: 2rem;
  line-height: 2.125rem;
  font-weight: 600;
  letter-spacing: 0;
}

.Type_h4__1YO__ {
  font-size: 1.625rem;
  line-height: 2.125rem;
  font-weight: 600;
  letter-spacing: 0;
}

.Type_h5__ohnMW {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 600;
  letter-spacing: 0;
}

.Type_h6__2Ulu8 {
  font-size: 1rem;
  line-height: 1.375rem;
  font-weight: 600;
  letter-spacing: 0.18px;
}

.Type_h7__3XXPo {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 600;
  letter-spacing: 0.16px;
}

.Type_meta-small__3B6ca {
  font-size: 0.625rem;
  line-height: 1.125rem;
  font-weight: 600;
  letter-spacing: 3px;
  text-transform: uppercase;
}
.Type_meta-regular__3IOmR {
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-weight: 600;
  letter-spacing: 3px;
  text-transform: uppercase;
}
.Loading_LoadingPage__28K3e {
  height: 100vh;
  background-color: #161616;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.Loading_Content__kOTLC {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}

.Loading_Logo__3XYOx {
  color: #0062ff;
  margin-bottom: 20px;
  animation: Loading_pulse__22AWb 1.2s linear infinite;
}

.Loading_Title__2zhEl {
  margin-bottom: 8px;
}

@keyframes Loading_pulse__22AWb {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.25, 1.25);
  }
  100% {
    transform: scale(1, 1);
  }
}
.Button_primary__1jqmx {
  color: #ffffff;
  background-color: #0062ff;
}
.Button_primary__1jqmx:hover, .Button_primary__1jqmx:focus {
  background-color: #064ada;
}
.Button_primary__1jqmx:active {
  background-color: #061f81;
}

.Button_secondary__9tCO1 {
  color: #ffffff;
  background-color: #8d8d8d;
}
.Button_secondary__9tCO1:hover {
  background-color: #525252;
}
.Button_secondary__9tCO1:active {
  background-color: #393939;
}

.Button_tertiary__3zKj4 {
  color: #161616;
  background-color: #ffffff;
}
.Button_tertiary__3zKj4:hover, .Button_tertiary__3zKj4:focus {
  background-color: #c6c6c6;
}
.Button_tertiary__3zKj4:active {
  background-color: #8d8d8d;
}

.Button_important__3OcI7 {
  color: #ffffff;
  background-color: #da1e28;
}
.Button_important__3OcI7:hover {
  background-color: #a51920;
}
.Button_important__3OcI7:active {
  background-color: #750e13;
}

.Button_button__1L25E {
  position: relative;
  height: 40px;
  padding: 0 20px;
  border: 0;
  cursor: pointer;
  transition: background-color ease 300ms;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.Button_button__1L25E:focus {
  outline: 1px solid #ffffff;
  outline-offset: 1px;
}
.Button_button__1L25E.Button_visuallyDisabled__2ZTNh, .Button_button__1L25E:disabled {
  cursor: default;
  background-color: #393939;
  color: #c6c6c6;
}

.Button_large__IQ1Cm {
  height: 48px;
  font-size: 16px;
  padding: 0 28px;
}

.Button_small__1zt9K {
  height: 32px;
  font-size: 12px;
}

.Button_text__2chyI {
  font-weight: 600;
}
.Button_text__2chyI:focus {
  outline: 1px solid #ffffff;
  outline-offset: 1px;
}

.Button_responsive__QgA8Z {
  padding: 6.5px 20px;
}
.Button_responsive__QgA8Z .Button_text__2chyI {
  font-size: 0.75rem;
}

.Button_IconWrapper__2SZ-U {
  margin-left: 10px;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}
.Button_IconWrappersmall__2onAM {
  width: 14px;
  height: 14px;
}
.Button_IconWrapperlarge__WQOHl {
  width: 20px;
  height: 20px;
}

.Button_withIcon__hCi-C {
  padding-right: 16px;
}
.Button_withIconsmall__2AyNr {
  padding-right: 14px;
}
.Button_withIconlarge__1yemf {
  padding-right: 20px;
}

.Button_square__gGpff {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 40px;
  height: 40px;
}
.Button_square__gGpff .Button_IconWrapper__2SZ-U {
  margin: 0;
}
.Button_squaresmall__2yZXy {
  width: 32px;
  height: 32px;
}
.Button_squarelarge__1Mtqo {
  width: 48px;
  height: 48px;
}

.Button_textLink__2N0DF {
  color: #408bfd;
  font-weight: 600;
  background-color: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  height: auto;
  line-height: 20px;
  transition: 0.3s color ease-in-out;
}
.Button_textLink__2N0DF:hover, .Button_textLink__2N0DF:focus {
  color: #064ada;
}
.Button_textLink__2N0DF:focus {
  color: #0062ff;
  outline: 1px solid #0062ff;
}
.Button_textLink__2N0DF:active {
  color: #0630ad;
}
.Button_textLinkSecondary__3xU84 {
  color: #ffffff;
}
.Button_textLinkSecondary__3xU84:hover {
  color: #c6c6c6;
}
.Button_textLinkSecondary__3xU84:focus {
  color: #a8a8a8;
  outline: 1px solid #c6c6c6;
}
.Button_textLinkSecondary__3xU84:active {
  color: #8d8d8d;
}
.Button_textLinkUnderline__1n1Cs {
  border-bottom: 1px solid;
  border-bottom-color: inherit;
}
.Button_textLink__2N0DF:disabled {
  color: #6f6f6f;
  background-color: transparent;
}
.Button_textLink__2N0DF .Button_IconWrapper__2SZ-U {
  margin-left: 5px;
}
.ErrorScreen_Screen__2huNl {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  padding: 20px;
}

.ErrorScreen_Content__Q6rw2 {
  display: flex;
  width: 100%;
  max-width: 500px;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  padding: 20px;
}

.ErrorScreen_Title__1LDNW {
  margin-bottom: 8px;
  text-align: center;
}

.ErrorScreen_Subtitle__C1-a_ {
  text-align: center;
}

.ErrorScreen_Button__Nq3_x {
  margin-top: 22px;
  width: 160px;
}
.PageTitle_Title__25a9Q {
  display: flex;
  padding: 20px 0;
  align-items: center;
}
.PageTitle_TitleWithLink__3MPav {
  flex-direction: column;
  align-items: flex-start;
  font-weight: 600;
}

.PageTitle_Text__ahkCq {
  margin-right: 11px;
  display: flex;
  align-items: center;
}
.PageTitle_Text__ahkCq .PageTitle_Text__ahkCq {
  margin-left: 11px;
}

@media screen and (min-width: 1025px) {
  .PageTitle_Title__25a9Q {
    padding: 0;
  }
}
.PageTitle_LinkIcon__248Vi {
  transform: rotate(90deg);
  transform-origin: center;
  width: 16px;
  color: #c6c6c6;
  display: flex;
  align-items: center;
}

.PageTitle_BreadCrumb__DduKL {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-weight: 600;
}

.PageTitle_LinkText__3H8Pe {
  margin-left: 5px;
}
.ScreenLayout_Screen__2l5c1 {
  height: 100%;
}

.ScreenLayout_HeaderWrapper__3Tg_x {
  width: 100%;
}

.ScreenLayout_Header__2e8Ws {
  display: flex;
  width: 100%;
  flex-flow: column-reverse;
  justify-content: space-between;
  align-content: flex-end;
  align-items: flex-start;
}

.ScreenLayout_Logo__39UeR {
  display: flex;
  flex: 1 0 auto;
  color: #0062ff;
}

.ScreenLayout_HeartbeatWrapper__1GsH3 {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 99;
}

.ScreenLayout_Heartbeat__C9UOU {
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #262626;
  padding: 12px 20px;
  width: 100%;
  height: 44px;
}
.ScreenLayout_HeartbeatLink__tWTjD {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ScreenLayout_StatusText__2-OW2 {
  margin-right: 10px;
}
.ScreenLayout_StatusText__2-OW2::first-letter {
  text-transform: uppercase;
}

.ScreenLayout_Content__1Php9 {
  position: fixed;
  top: 44px;
  bottom: 62px;
  right: 0;
  left: 0;
  padding: 0;
}
.ScreenLayout_ContentContainer__LT6ql {
  position: relative;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.ScreenLayout_DesktopHeader__16cU4 {
  display: none;
}

.ScreenLayout_Subtitle__lma4Y {
  margin-left: 11px;
}

.ScreenLayout_BreadCrumb__23Eer {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-weight: 600;
}

.ScreenLayout_LinkIcon__1MJy7 {
  transform: rotate(90deg);
  transform-origin: center;
  display: inline-block;
  width: 16px;
  height: 16px;
  color: #c6c6c6;
}

@media screen and (min-width: 1025px) {
  .ScreenLayout_DesktopHeader__16cU4 {
    display: flex;
    align-items: center;
  }
  .ScreenLayout_DesktopHeaderWithLink__13u0S {
    flex-direction: column;
    align-items: flex-start;
  }
  .ScreenLayout_HeaderWrapper__3Tg_x {
    background-color: #161616;
    position: fixed;
    top: 0;
    z-index: 99;
    max-width: calc(100vw - 78px);
  }
  .ScreenLayout_Header__2e8Ws {
    display: flex;
    min-height: 70px;
    flex-flow: row nowrap;
    padding: 0 20px;
    align-items: center;
  }
  .ScreenLayout_HeaderWithLink__-F6gC {
    padding: 30px 20px;
  }
  .ScreenLayout_Logo__39UeR {
    display: none;
  }
  .ScreenLayout_HeartbeatWrapper__1GsH3 {
    position: relative;
    width: auto;
  }
  .ScreenLayout_Heartbeat__C9UOU {
    background-color: #161616;
    justify-content: space-between;
    padding: 0;
    width: auto;
    height: auto;
  }
  .ScreenLayout_Content__1Php9 {
    position: fixed;
    top: 70px;
    bottom: 0;
    right: 0;
    left: 78px;
  }
}
.HeartbeatStatus_Heartbeat__2gKDM {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.HeartbeatStatus_StatusContainer__38C5O {
  position: relative;
  display: flex;
  width: 16px;
  height: 16px;
  margin-right: 10px;
  background-color: transparent;
  color: transparent;
}
.HeartbeatStatus_StatusContainerConnected__3DOJw {
  color: #1baa55;
}
.HeartbeatStatus_StatusContainerPartial__2pamV {
  color: #f37113;
}
.HeartbeatStatus_StatusContainerDisconnected__2F7B4 {
  color: #da1e28;
}
.HeartbeatStatus_StatusContainerError__2XIs0 {
  color: #6f6f6f;
}
.HeartbeatStatus_StatusIcon__3IYtQ {
  position: absolute;
  height: 16px;
  width: 16px;
  top: 0;
  left: 0;
  border-radius: 50%;
  background-color: currentColor;
}
.HeartbeatStatus_StatusIconDisconnectedError__Q8ghf {
  position: absolute;
  color: #000000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.HeartbeatStatus_StatusPulse__fqcKy {
  border: 1px solid currentColor;
  border-radius: 30px;
  height: 16px;
  width: 16px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.7;
}
.HeartbeatStatus_StatusPulseInner__3k_lz {
  animation: HeartbeatStatus_pulse-inner__aXWuS 2s linear infinite;
}
.HeartbeatStatus_StatusPulseOuter__1ZG5e {
  animation: HeartbeatStatus_pulse-outer__15JLn 2s linear infinite;
}

@keyframes HeartbeatStatus_pulse-inner__aXWuS {
  0% {
    transform: scale(0.8, 0.8);
  }
  40% {
    transform: scale(1.4, 1.4);
  }
  80% {
    transform: scale(1.4, 1.4);
  }
  100% {
    transform: scale(0.8, 0.8);
  }
}
@keyframes HeartbeatStatus_pulse-outer__15JLn {
  0% {
    transform: scale(0.8, 0.8);
  }
  40% {
    transform: scale(1.8, 1.8);
  }
  80% {
    transform: scale(1.8, 1.8);
  }
  100% {
    transform: scale(0.8, 0.8);
  }
}
.FilterContentsSection_Container__2SlyK {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: grid;
  grid-template-rows: auto 1fr;
}

.FilterContentsSection_HeaderWrapper__3fGuC {
  width: 100%;
  height: auto;
  border-style: none;
}

.FilterContentsSection_FilterContentWrapper__2h1TH {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
}

.FilterContentsSection_Filter__2BiJp {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-style: none;
}

.FilterContentsSection_ContentModalWrapper__2lncr {
  position: absolute;
  background-color: #101010;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100%;
}

.FilterContentsSection_ContentContainer__3-PGq {
  display: none;
  flex-flow: column;
  z-index: 1;
  position: relative;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  height: 100%;
}

.FilterContentsSection_MobileFocusContent__2KDHr .FilterContentsSection_Filter__2BiJp {
  display: none;
}
.FilterContentsSection_MobileFocusContent__2KDHr .FilterContentsSection_ContentContainer__3-PGq {
  display: flex;
}

.FilterContentsSection_FiltersPanel__d-LkW {
  display: flex;
  position: relative;
  flex-flow: column;
  justify-content: flex-start;
  min-height: 100%;
  z-index: 1;
}

@media screen and (min-width: 1025px) {
  .FilterContentsSection_HeaderWrapper__3fGuC {
    border-top: solid 0.25px #393939;
    border-bottom: solid 0.25px #393939;
  }
  .FilterContentsSection_FilterContentWrapper__2h1TH {
    overflow: hidden;
  }
  .FilterContentsSection_Filter__2BiJp {
    display: flex;
    position: relative;
    flex: 1 0 25%;
    overflow-y: auto;
    margin: 0;
    min-width: 370px;
    max-width: 600px;
    border-right: solid 0.25px #393939;
  }
  .FilterContentsSection_ContentModalWrapper__2lncr {
    position: relative;
    overflow-y: auto;
  }
  .FilterContentsSection_ContentContainer__3-PGq {
    display: flex;
  }
  .FilterContentsSection_MobileFocusContent__2KDHr .FilterContentsSection_Filter__2BiJp {
    display: flex;
  }
  .FilterContentsSection_MobileFocusContent__2KDHr .FilterContentsSection_ContentContainer__3-PGq {
    display: flex;
  }
}
.Dropdown_Label__15Bgl {
  margin: 0 0 8px;
}

.Dropdown_DropdownWrapper__3bEdV {
  position: relative;
  display: flex;
  flex-direction: column;
}

.Dropdown_DropdownButton__2Z9PI {
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.16px;
  transition-property: border-color;
  transition-duration: 120ms;
  transition-timing-function: cubic-bezier(0, 1.08, 1, 1);
  border: 1px solid #525252;
  padding: 0 40px 0 10px;
  background: #262626;
  color: #ffffff;
  text-transform: capitalize;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  height: 40px;
  position: relative;
}
.Dropdown_DropdownButton__2Z9PI:hover, .Dropdown_DropdownButton__2Z9PI:focus {
  border: 1px solid #8d8d8d;
  outline: none;
}
.Dropdown_DropdownButton__2Z9PI:disabled {
  cursor: not-allowed;
  border: 1px solid #525252;
  color: #6f6f6f;
}
.Dropdown_DropdownButtonSelected__1f_4N {
  background-color: #393939;
}
.Dropdown_DropdownButtonSmall__3VvRk {
  height: 32px;
  padding: 0 40px 0 10px;
}

@media screen and (min-width: 1025px) {
  .Dropdown_DropdownButton__2Z9PI {
    min-width: 120px;
    max-width: 100%;
  }
}
.Dropdown_Icon__17rRd {
  display: flex;
  margin-left: 8px;
  transition: transform 0.3s ease-in-out;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
}
.Dropdown_IconOpen__GOsHy {
  transform: rotateX(180deg);
}
.Dropdown_IconSmall__19BqN {
  top: 5px;
}

.Dropdown_Dropdown__2dJHa {
  position: absolute;
  background-color: #262626;
  z-index: 110;
  list-style-type: none;
  padding: 0;
  top: calc(100% - 1px);
  left: 0;
  margin: 0;
  min-width: 100%;
}
.Dropdown_DropdownNoLabel__1oUYj {
  top: 39px;
}
.Dropdown_DropdownNoLabelSmall__2BQqZ {
  top: 31px;
}
.Dropdown_DropdownOpen__nRnOg {
  border: 1px solid #525252;
}
.Dropdown_Dropdown__2dJHa:hover {
  border-color: #8d8d8d;
}

.Dropdown_DropdownItem__LCU8i {
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.16px;
  transition-property: background-color;
  transition-duration: 120ms;
  transition-timing-function: cubic-bezier(0, 1.08, 1, 1);
  padding: 10px;
  height: 40px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-content: center;
  border-bottom: 1px solid #393939;
}
.Dropdown_DropdownItem__LCU8i:last-child {
  border-bottom: 0;
}
.Dropdown_DropdownItemSelected__1gJOi {
  background-color: #0062ff;
}
.Dropdown_DropdownItemSmall__2V-Na {
  height: 32px;
  padding: 5px;
  padding-left: 10px;
}

.Dropdown_DropdownButton__2Z9PI:hover ~ .Dropdown_Dropdown__2dJHa {
  border-color: #8d8d8d;
}

.Dropdown_DropdownMainContent__3ewht {
  display: flex;
  flex-grow: 1;
  max-width: 100%;
  overflow: hidden;
  align-items: center;
}

.Dropdown_DropdownLabel__39nsz {
  white-space: nowrap;
  margin: 0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Dropdown_DropdownLabelWithAltText__1sFxs {
  margin-right: 40px;
}

.Dropdown_DropdownAltText__3DZzf {
  white-space: nowrap;
}

.Dropdown_IconWrapper__33RMu {
  margin-right: 10px;
  display: flex;
}

.Dropdown_AltTextWrapper__342yn {
  display: flex;
  margin-left: auto;
}
.SinglePipeline_singlePipeline__2f4IQ {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  /* stylelint-disable-next-line selector-max-universal */
}
.SinglePipeline_singlePipeline__2f4IQ > * {
  margin-right: 12px;
}

.SinglePipeline_changeButton__pqwL_ {
  margin-right: 0;
  margin-left: auto;
}
.PipelineSelect_FiberSelect__30AjK {
  margin-top: 20px;
}
.RecentAreas_resultsHeading__2XlDF {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 20px;
}
.RecentSearchItem_recentSearchItem__3VX8I {
  display: block;
  width: 100%;
  padding: 20px;
  background-color: #262626;
  min-width: 300px;
  cursor: pointer;
}
.RecentSearchItem_recentSearchItem__3VX8I:not(:last-child) {
  margin-bottom: 10px;
}
.RecentSearchItem_recentSearchItem__3VX8I.RecentSearchItem_active__3XZ3p {
  background-color: #393939;
}
.RecentSearchItem_recentSearchItem__3VX8I:hover {
  background-color: #101010;
  border: 1px solid #a8a8a8;
  padding: 19px;
}
.RecentSearchItem_recentSearchItem__3VX8I:focus {
  outline: none;
  border: 1px solid #ffffff;
  padding: 19px;
}

.RecentSearchItem_textRow__16qYJ {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.RecentSearchItem_arrowRight__tq-o4 {
  display: inline-block;
  line-height: 1em;
  vertical-align: middle;
  margin: 0 0.5em;
}
/* stylelint-disable */
.js-plotly-plot .plotly .modebar-container {
  top: -66px !important;
}
.js-plotly-plot .plotly .modebar {
  right: 40px !important;
}
.js-plotly-plot .plotly .modebar,
.js-plotly-plot .plotly .modebar-group {
  background-color: transparent !important;
  height: auto;
}
.js-plotly-plot .plotly .modebar-group:empty {
  display: none;
}
.js-plotly-plot .plotly .modebar-btn {
  background-color: #262626;
  padding: 8px !important;
  margin-left: 8px;
}
.js-plotly-plot .plotly .modebar-btn:first-child {
  margin-left: 0;
}
.js-plotly-plot .plotly .modebar-btn:hover, .js-plotly-plot .plotly .modebar-btn.active {
  background-color: #525252;
}
.js-plotly-plot .plotly .line:first-child {
  fill: #a8a8a8;
}
.js-plotly-plot .plotly .line:last-child {
  fill: #6f6f6f;
}
.js-plotly-plot .plotly .hovertext .line:last-child {
  fill: #a8a8a8;
}

/* stylelint-enable */
.AnalysisCharts_ShadingSelector__39JVi {
  position: relative;
  display: flex;
  padding: 15px;
  padding-right: 10px;
  float: right;
  flex-flow: column;
  margin-right: 35px;
  justify-content: flex-start;
  margin-bottom: -40px;
  z-index: 10;
}

.AnalysisCharts_Wrapper__3cfE6 {
  position: relative;
  padding: 20px;
  padding-right: 0;
}

.AnalysisCharts_TitleWrapper__sEFzg {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-end;
}

.AnalysisCharts_Title__Fc7yu {
  display: block;
  margin-right: 15px;
}

.AnalysisCharts_SubTitle__2hmRG {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 0;
  margin-left: 30px;
  transform: translateX(-50%) rotate(-90deg);
}

.AnalysisCharts_Plot__1HGde {
  width: calc(100% - 40px);
  margin-bottom: 30px;
  margin-left: 40px;
}
.AnalysisCharts_Plot__1HGde.AnalysisCharts_Loading__zhtJN {
  opacity: 0.15;
}

.AnalysisCharts_Loader__2vvDm {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: auto;
}

.AnalysisCharts_ChartTypeWrapper__3Mf3G {
  display: flex;
  flex-flow: row nowrap;
}

@media screen and (min-width: 1025px) {
  .AnalysisCharts_Wrapper__3cfE6 {
    padding: 40px;
    padding-right: 0;
  }
  .AnalysisCharts_SubTitle__2hmRG {
    margin-left: 50px;
  }
}
.LoadingSpinner_Wrapper__3_GPU {
  display: grid;
}

@keyframes LoadingSpinner_rotate__2GbGp {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.LoadingSpinner_Spinner__CLtXN {
  animation: LoadingSpinner_rotate__2GbGp 1s linear infinite;
  width: 100%;
  height: 100%;
}
.LoadingSpinner_Spinner__CLtXN::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 8%;
  border-radius: 50%;
  background: conic-gradient(#393939 180deg, #0062ff);
  -webkit-mask: linear-gradient(#ffffff 0 0) content-box, linear-gradient(#ffffff 0 0);
          mask: linear-gradient(#ffffff 0 0) content-box, linear-gradient(#ffffff 0 0);
  -webkit-mask-composite: xor;
          mask-composite: exclude;
}
.LoadingSpinner_Spinner__CLtXN::after {
  content: "";
  width: 8%;
  height: 8%;
  left: 45%;
  background-color: #0062ff;
  position: absolute;
  border-radius: 50%;
}

.LoadingSpinner_Percentage__1iHwQ,
.LoadingSpinner_Spinner__CLtXN {
  margin: auto;
  grid-column: 1;
  grid-row: 1;
}

.LoadingSpinner_Percentage__1iHwQ {
  font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
  letter-spacing: 0;
  font-weight: 600;
}
.LoadingSpinnerWrapper_Spinner__J7pYf {
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 300ms ease-in-out;
  display: none;
}
.LoadingSpinnerWrapper_SpinnerVisible__1LAfn {
  display: flex;
  flex-direction: row;
  opacity: 1;
  width: 100%;
  height: 100%;
  padding: 50px;
}
.StatusIndicator_StatusIndicator__1pbxP {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.StatusIndicator_Indicator__umGAg {
  display: block;
  width: 12px;
  height: 12px;
  margin-right: 6px;
  border-radius: 100%;
  background-color: #f37113;
}
.StatusIndicator_Indicator__umGAg.StatusIndicator_Live__3HAQ4 {
  background-color: #1baa55;
  box-shadow: 0 0 0 0 #1baa55;
  animation: StatusIndicator_livePulse__11Osl 2.5s infinite;
}
.StatusIndicator_Indicator__umGAg.StatusIndicator_Paused__r9gm_ {
  background-color: #da1e28;
}

.StatusIndicator_Toggle__3X_3v {
  margin-left: 15px;
}

@keyframes StatusIndicator_livePulse__11Osl {
  0% {
    box-shadow: 0 0 0 0 rgba(27, 170, 85, 0.5);
  }
  85% {
    box-shadow: 0 0 0 12px rgba(27, 170, 85, 0);
  }
  100% {
    box-shadow: 0 0 0 24px transparent;
  }
}
.TimeTicker_TimeTicker__21tW6 {
  margin: 4px 0;
}
.RangeSlider_container__2aaU5 {
  position: relative;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 42px;
}

.RangeSlider_minValue__Hby4Z,
.RangeSlider_maxValue__3QN6N {
  color: #6f6f6f;
  line-height: 1em;
}

.RangeSlider_rangeInputs__3Ej_8 {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 12px;
  margin: 0 6.5px;
}

.RangeSlider_slider__1Gz2i {
  position: relative;
  width: 100%;
  height: 4px;
}

.RangeSlider_sliderTrack__vjyLI,
.RangeSlider_sliderRange__28uoB,
.RangeSlider_sliderLimits__39Hcz,
.RangeSlider_sliderLeftValue__1foJY,
.RangeSlider_sliderRightValue__1jMRK {
  position: absolute;
}

.RangeSlider_sliderTrack__vjyLI,
.RangeSlider_sliderRange__28uoB,
.RangeSlider_sliderLimits__39Hcz {
  height: 100%;
}

.RangeSlider_sliderTrack__vjyLI {
  background-color: #393939;
  width: 100%;
  z-index: 1;
  border-radius: 2px;
}

.RangeSlider_sliderLimits__39Hcz {
  background-color: #6f6f6f;
  width: 0%;
  z-index: 2;
  border-radius: 2px;
}

.RangeSlider_sliderRange__28uoB {
  background-color: #ffffff;
  z-index: 3;
}

.RangeSlider_handle__3UL2u {
  display: block;
  position: absolute;
  background-color: #ffffff;
  height: 12px;
  width: 3px;
  border-radius: 0;
  pointer-events: none;
  margin-top: 2px;
  z-index: 5;
}
.RangeSlider_handle__3UL2u.RangeSlider_handleLeft__36lCT {
  left: 0;
  transform: translate(-50%, -50%);
}
.RangeSlider_handle__3UL2u.RangeSlider_handleRight__22kXz {
  right: 0;
  transform: translate(50%, -50%);
}

.RangeSlider_sliderLeftValue__1foJY,
.RangeSlider_sliderRightValue__1jMRK {
  color: #F4F4F4;
  background-color: #262626;
  padding: 5px;
  line-height: 1em;
  bottom: 22px;
}
.RangeSlider_sliderLeftValue__1foJY::after,
.RangeSlider_sliderRightValue__1jMRK::after {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border: 2.5px solid #262626;
  border-bottom-color: transparent;
  top: 100%;
}

.RangeSlider_sliderLeftValue__1foJY {
  right: 3px;
}
.RangeSlider_sliderLeftValue__1foJY::after {
  border-bottom-color: transparent;
  border-left-color: transparent;
  right: 0;
}

.RangeSlider_sliderRightValue__1jMRK {
  left: 3px;
}
.RangeSlider_sliderRightValue__1jMRK::after {
  border-right-color: transparent;
  left: 0;
}

.RangeSlider_thumb__3XjZJ::-webkit-slider-thumb, .RangeSlider_thumb__3XjZJ {
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.RangeSlider_single__2ckqT .RangeSlider_handleRight__22kXz {
  display: none;
}
.RangeSlider_single__2ckqT .RangeSlider_sliderLeftValue__1foJY {
  right: auto;
  left: 1.5px;
  transform: translateX(-50%);
}
.RangeSlider_single__2ckqT .RangeSlider_sliderLeftValue__1foJY::after {
  content: none;
}

.RangeSlider_thumb__3XjZJ {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 100%;
  outline: none;
  margin: 0;
  /* stylelint-disable selector-max-compound-selectors */
  /* stylelint-enable selector-max-compound-selectors */
}
.RangeSlider_thumb__3XjZJ:nth-child(1):focus ~ .RangeSlider_slider__1Gz2i .RangeSlider_handleLeft__36lCT {
  outline: 1px solid #0062ff;
  z-index: 6;
}
.RangeSlider_thumb__3XjZJ:nth-child(1):focus ~ .RangeSlider_slider__1Gz2i .RangeSlider_handleRight__22kXz {
  z-index: 5;
}
.RangeSlider_thumb__3XjZJ:nth-child(2):focus ~ .RangeSlider_slider__1Gz2i .RangeSlider_handleLeft__36lCT {
  z-index: 5;
}
.RangeSlider_thumb__3XjZJ:nth-child(2):focus ~ .RangeSlider_slider__1Gz2i .RangeSlider_handleRight__22kXz {
  outline: 1px solid #0062ff;
  z-index: 6;
}

/* For Chrome browsers */
.RangeSlider_thumb__3XjZJ::-webkit-slider-thumb {
  /* Removing the default appearance */
  background-color: transparent;
  border: 0;
  height: 29px;
  width: 29px;
  pointer-events: all;
  position: relative;
  cursor: grab;
}
.RangeSlider_thumb__3XjZJ::-webkit-slider-thumb:active {
  cursor: grabbing;
}

/* For Firefox browsers */
.RangeSlider_thumb__3XjZJ::-moz-range-thumb {
  background-color: transparent;
  border: 0;
  height: 29px;
  width: 29px;
  pointer-events: all;
  position: relative;
  cursor: grab;
}
.RangeSlider_thumb__3XjZJ::-moz-range-thumb:active {
  cursor: grabbing;
}

.RangeSlider_container__2aaU5[data-debug] .RangeSlider_thumb__3XjZJ::-webkit-slider-thumb {
  background-color: rgba(255, 255, 255, 0.25);
}
.RangeSlider_container__2aaU5[data-debug] .RangeSlider_thumb__3XjZJ::-moz-range-thumb {
  background-color: rgba(255, 255, 255, 0.25);
}

.RangeSlider_thumbLeft__1-6Qc {
  z-index: 5;
}

.RangeSlider_thumbRight__3sYVt {
  z-index: 6;
}
/* stylelint-disable */
.ShadingSelector_Container__Bcctw {
  flex: 1 0 auto;
  flex-flow: row nowrap;
  display: flex;
  padding: 0;
}

.ShadingSelector_KPRangeSlider__bBqJA {
  display: flex;
  padding-top: 0;
  padding-right: 0;
  min-width: 220px;
  position: relative;
  margin-bottom: -22px;
}

.ShadingSelector_ResetButton__3WecR {
  display: flex;
  width: 140px;
  right: 20px;
  padding: 0 15px;
  background-color: #393939;
  color: #ffffff;
}

.ShadingSelector_ResetButton__3WecR:hover {
  background-color: #262626;
}

.ShadingSelector_InactiveResetButton__1ffJ9 {
  filter: brightness(60%);
  background-color: transparent !important;
}
.EmptyListState_Empty__1kjpy {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.EmptyListState_EmptyDesc__3V99z {
  margin: 10px 0 20px;
}
@media screen and (min-width: 768px) {
  .EmptyListState_EmptyDesc__3V99z {
    white-space: pre-line;
  }
}
.Inputs_Wrapper__10-Tz {
  position: relative;
  display: flex;
  flex-flow: column;
  width: 100%;
}

.Inputs_Input__2saQV {
  width: 100%;
  height: 40px;
  border-style: none;
  padding: 10px;
  color: #ffffff;
  background-color: #262626;
  outline-style: none;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.16px;
  border-bottom: 3px solid #8d8d8d;
}
.Inputs_Input__2saQV:focus, .Inputs_Input__2saQV:hover {
  border-bottom-color: #ffffff;
}
.Inputs_Input__2saQV::placeholder {
  color: #6f6f6f;
}
.Inputs_Input__2saQV:disabled {
  color: #6f6f6f;
  cursor: not-allowed;
}
.Inputs_Input__2saQV:disabled:focus, .Inputs_Input__2saQV:disabled:hover {
  border-bottom-color: #8d8d8d;
}
.Inputs_InputError__1YufO {
  border-bottom-color: #da1e28;
}
.Inputs_InputError__1YufO:focus, .Inputs_InputError__1YufO:hover {
  border-bottom-color: #da1e28;
}
.Inputs_InputSuccess__2B8k2 {
  border-bottom-color: #1baa55;
}
.Inputs_InputSuccess__2B8k2:focus, .Inputs_InputSuccess__2B8k2:hover {
  border-bottom-color: #1baa55;
}
.Inputs_InputSmall__3owAx {
  padding: 5px 10px;
  height: 32px;
}

.Inputs_Darker__1Gazy {
  color: #6f6f6f;
}

.Inputs_Label__2vKxx {
  margin: 0 0 8px;
}

.Inputs_Disabled__38zdt {
  opacity: 0.5;
}

.Inputs_Message__1C14f {
  color: #ffffff;
  font-size: 10px;
  line-height: 16px;
  margin-top: 2px;
}
.Inputs_MessageSuccess__6xz7G {
  color: #1baa55;
}
.Inputs_MessageError__1NJ_p {
  color: #da1e28;
}

/* Chrome, Safari, Edge, Opera */
.Inputs_numberInput__ckaxR::-webkit-outer-spin-button,
.Inputs_numberInput__ckaxR::-webkit-inner-spin-button {
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.Inputs_numberInput__ckaxR[type=number] {
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -moz-appearance: textfield;
}
.RangeUnitToggle_FilterRow__3IFGM {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: end;
  margin-bottom: -22px;
}
.ToggleSwitch_Switch__1hy7m {
  position: relative;
  display: block;
  width: 36px;
  height: 20px;
  background-color: #393939;
  border: 0;
  border-radius: 18px;
  margin: 0 5px;
  cursor: pointer;
}
.ToggleSwitch_Switch__1hy7m::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border: 2px solid #393939;
  background-color: #F4F4F4;
  transition: all 250ms;
}

.ToggleSwitch_active__1ODPQ::after {
  content: "";
  left: calc(100% - 20px);
}

.ToggleSwitch_ToggleSwitch__Lxyud {
  display: flex;
}
.ToggleSwitch_ToggleSwitch__Lxyud:hover .ToggleSwitch_Switch__1hy7m::after {
  background-color: #a8a8a8;
}
.RangeSelector_SearchRow__3NcA_ {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: flex-end;
  /* stylelint-disable-next-line selector-max-universal */
}
.RangeSelector_SearchRow__3NcA_ > * {
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.RangeSelector_SearchRow__3NcA_ > :first-child {
  margin-left: 0;
}

.RangeSelector_SearchRow__3NcA_ > :last-child {
  margin-right: 0;
}

.RangeSelector_KPRangeSlider__SBpkX {
  margin-bottom: 28px;
}

.RangeSelector_KPTextInputSeparator__1xSAr {
  border: solid 1px #393939;
  width: 8px;
  height: 1px;
  margin-bottom: 20px;
  margin-left: 0;
  margin-right: 0;
}

.RangeSelector_searchButton__13WYS {
  min-width: 90px;
}

.RangeSelector_FilterText__zFEIP {
  margin: 0 0 5px;
  max-width: 280px;
}
.AnalysisFilters_FilterAttribute__2bPYe {
  padding: 30px 20px;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
}
.AnalysisFilters_FilterAttribute__2bPYe:last-child {
  align-items: stretch;
  height: 100%;
}

.AnalysisFilters_FiltersPanelSeparator__2gXko {
  display: block;
  width: 100%;
  border: 0;
  border-bottom: solid 1px #393939;
  height: 0;
  margin: 0;
}
.MobilePageTitle_MobilePageTitle__2Ch1K {
  width: 100%;
  display: block;
  padding: 20px 20px;
  border: 1px solid #393939;
}

@media screen and (min-width: 1025px) {
  .MobilePageTitle_MobilePageTitle__2Ch1K {
    display: none;
  }
}
.gm-style .gm-style-iw-c {
  padding: 0;
  border-radius: 0;
  border-style: none;
  border: solid 1px #8d8d8d;
  transform: translate(40px, 0);
}

.gm-style .gm-style-iw-d::-webkit-scrollbar {
  display: none;
}

.gm-style .gm-style-iw-t::after {
  background: rgb(22, 22, 22);
  transform: translate(32px, 26px) rotate(45deg);
  box-shadow: none;
  border-left: solid 1px #8d8d8d;
  border-bottom: solid 1px #8d8d8d;
}

/* stylelint-disable-next-line selector-max-type */
.gm-style-iw-c > button {
  /* stylelint-disable-next-line declaration-no-important */
  display: none !important; /* override inline display style */
}
.CheckboxGroup_Checkbox__3mCbl {
  flex-basis: 100%;
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding-bottom: 12px;
}
.CheckboxGroup_CheckboxNoPad__1zT4E {
  padding-bottom: 0;
}
.CheckboxGroup_Checkbox__3mCbl:hover {
  cursor: pointer;
}

.CheckboxGroup_CheckboxLabel__2sdLe {
  padding: 0;
  margin: 0;
  margin-left: 10px;
  text-transform: capitalize;
}
.CheckboxGroup_CheckboxLabelNoCapitalize__1EzUi {
  text-transform: none;
}

.CheckboxGroup_CheckboxInput__1fvJy {
  position: absolute;
  z-index: -1;
  opacity: 0;
  margin: 0;
}
.CheckboxGroup_CheckboxInput__1fvJy:focus + .CheckboxGroup_CheckboxLabel__2sdLe {
  outline: 5px auto -webkit-focus-ring-color;
}

.CheckboxGroup_CheckboxWrapper__1jjdd {
  display: flex;
  flex-direction: column;
  padding: 0 18px;
}

.CheckboxGroup_GroupLabel__2MYq7 {
  margin-bottom: 14px;
}
.EventMapButtons_Button__1A1TK {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row nowrap;
  background-color: #262626;
  border: solid 1px #525252;
  color: #ffffff;
  margin: 0;
  cursor: pointer;
  transition: background-color ease 300ms;
  text-align: center;
  padding: 6px 10px;
}

.EventMapButtons_Reset__3_SZ6 {
  position: absolute;
  left: 20px;
  top: 20px;
  visibility: hidden;
}
.EventMapButtons_ResetShow__8Vjxk {
  visibility: visible;
}

.EventMapButtons_SearchButton__1Wo6S {
  position: absolute;
  left: 50%;
  top: 20px;
  transform: translateX(-50%);
  height: 37px;
}
.EventMapButtons_SearchButtonLoading__36QUp {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #262626;
  display: flex;
  align-items: center;
  justify-content: center;
}

.EventMapButtons_Text__yc-Gu {
  padding: 0;
  margin-right: 25px;
}
.eventToolTip_ToolTip__1T_QD {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #161616;
  padding: 10px;
  display: flex;
  flex-flow: column;
}
.eventToolTip_ToolTipType__1__Tr {
  text-transform: capitalize;
}

.eventToolTip_Icon__3DEzE {
  position: relative;
  text-transform: capitalize;
  padding-left: 15px;
  margin-top: 4px;
}
.eventToolTip_Icon__3DEzE::before {
  content: "";
  position: absolute;
  top: 5px;
  left: 2px;
  width: 8px;
  height: 8px;
  background-color: #0062ff;
  border-radius: 50%;
}
.eventToolTip_IconMedium__1FcBd::before {
  background-color: #f37113;
}
.eventToolTip_IconHigh__Lpxjz::before {
  background-color: #da1e28;
}

.eventToolTip_PigLocation__rvC2y {
  margin-top: 4px;
}
.BaseSpiderfy_Cluster__2GL9q {
  z-index: 150;
}
.EventMap_Loading__1Scl1 {
  height: 100%;
  width: 100%;
}

.EventMap_Container__3JJ_l {
  position: relative;
  height: 100%;
  width: 100%;
}

.EventMap_Map__22saK {
  height: 100%;
  width: 100%;
  /* stylelint-disable-next-line selector-max-type */
}
.EventMap_Map__22saK iframe {
  background-color: #262626; /* Set loading tile background (Issue: https://github.com/tomchentw/react-google-maps/issues/764) */
}

.EventMap_MapOptions__BFLia {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 18px 0 6px;
  margin: 20px 20px 40px;
  display: flex;
  flex-flow: column;
  background-color: #262626;
  border: solid 1px #525252;
}

.EventMap_LegendDivider__35T5N {
  width: 100%;
  border: solid 1px #525252;
}
.PipelineLegend_PipelineLegend__3V6PG {
  display: none;
}

.PipelineLegend_Pipeline__1YMgI {
  display: flex;
  color: #ffffff;
  background-color: transparent;
  width: 100%;
  vertical-align: middle;
  height: 30px;
  padding: 0 18px;
}
.PipelineLegend_Pipeline__1YMgI:hover {
  background-color: #000000;
}
.PipelineLegend_PipelineFocused__2z2rV {
  background-color: #393939;
  border-left: 0.3em solid #0062ff;
}
.PipelineLegend_PipelineFocused__2z2rV:hover {
  background-color: #000000;
}

@media screen and (min-width: 1025px) {
  .PipelineLegend_PipelineLegend__3V6PG {
    display: flex;
    flex-flow: column;
    max-height: 400px;
    overflow-y: auto;
  }
  .PipelineLegend_PipelineLegendRow__2sStN {
    display: flex;
    flex-flow: row;
    align-items: center;
    height: 30px;
  }
  .PipelineLegend_PipelineColor__xds-v {
    stroke-width: 2;
    align-self: center;
    margin-right: 10px;
  }
  .PipelineLegend_PipelineName__3irEu {
    align-self: center;
  }
}
.SinglePipeline_singlePipeline__16kda {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  /* stylelint-disable-next-line selector-max-universal */
}
.SinglePipeline_singlePipeline__16kda > * {
  margin-right: 12px;
}

.SinglePipeline_changeButton__3i1X6 {
  margin-right: 0;
  margin-left: auto;
}
.PipelineSelect_FiberSelect__3n064 {
  margin-top: 20px;
}
.RecentAreas_resultsHeading__hDdyV {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 20px;
}
.RecentSearchItem_recentSearchItem__1Jtmf {
  display: block;
  width: 100%;
  padding: 20px;
  background-color: #262626;
  min-width: 300px;
  cursor: pointer;
}
.RecentSearchItem_recentSearchItem__1Jtmf:not(:last-child) {
  margin-bottom: 10px;
}
.RecentSearchItem_recentSearchItem__1Jtmf.RecentSearchItem_active__26CB8 {
  background-color: #393939;
}
.RecentSearchItem_recentSearchItem__1Jtmf:hover {
  background-color: #101010;
  border: 1px solid #a8a8a8;
  padding: 19px;
}
.RecentSearchItem_recentSearchItem__1Jtmf:focus {
  outline: none;
  border: 1px solid #ffffff;
  padding: 19px;
}

.RecentSearchItem_textRow__2qHHS {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.RecentSearchItem_arrowRight__2UeE5 {
  display: inline-block;
  line-height: 1em;
  vertical-align: middle;
  margin: 0 0.5em;
}
.RangeUnitToggle_FilterRow__2YtQ7 {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: end;
  margin-bottom: -22px;
}
.RangeSelector_SearchRow__26WP3 {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: flex-end;
  /* stylelint-disable-next-line selector-max-universal */
}
.RangeSelector_SearchRow__26WP3 > * {
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.RangeSelector_SearchRow__26WP3 > :first-child {
  margin-left: 0;
}

.RangeSelector_SearchRow__26WP3 > :last-child {
  margin-right: 0;
}

.RangeSelector_KPRangeSlider__22BqJ {
  margin-bottom: 28px;
}

.RangeSelector_KPTextInputSeparator__2acZR {
  border: solid 1px #393939;
  width: 8px;
  height: 1px;
  margin-bottom: 20px;
  margin-left: 0;
  margin-right: 0;
}

.RangeSelector_searchButton__GFZHi {
  min-width: 90px;
}

.RangeSelector_FilterText__3yc-w {
  margin: 0 0 5px;
  max-width: 280px;
}
.FakeLeakAnalysisFilters_FilterAttribute__37J9r {
  padding: 30px 20px;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
}
.FakeLeakAnalysisFilters_FilterAttribute__37J9r:last-child {
  align-items: stretch;
  height: 100%;
}

.FakeLeakAnalysisFilters_FiltersPanelSeparator__1Kdpy {
  display: block;
  width: 100%;
  border: 0;
  border-bottom: solid 1px #393939;
  height: 0;
  margin: 0;
}
.ShareDialog_Share__C0x-O {
  position: absolute;
  top: calc(100% + 10px);
  right: 20px;
  background-color: #262626;
  padding: 40px;
  z-index: 110;
  display: flex;
  flex-flow: column;
  border: solid 1px #393939;
  border-top-style: none;
  filter: drop-shadow(0 -1px 0 #393939);
}

.ShareDialog_Row__1dgcN {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.ShareDialog_Row__1dgcN:last-child {
  margin-bottom: 0;
}

.ShareDialog_InputWrapper__2ui7g {
  margin-right: 10px;
  /* stylelint-disable selector-max-type */
  /* stylelint-enable selector-max-type */
}
.ShareDialog_InputWrapper__2ui7g input {
  background-color: #161616;
}

@media screen and (min-width: 768px) {
  .ShareDialog_InputWrapper__2ui7g {
    width: 300px;
  }
}
.ShareDialog_ButtonScreenshot__35thG {
  border-style: none;
  background-color: transparent;
  color: #ffffff;
  text-decoration: underline;
  padding: 0;
  cursor: pointer;
  transition: 0.3s color ease-in-out;
}
.ShareDialog_ButtonScreenshot__35thG:hover {
  color: #c6c6c6;
}
.ShareDialog_ButtonScreenshot__35thG:focus {
  color: #a8a8a8;
  outline: 1px solid #c6c6c6;
}
.TimelineInfoBar_TimelineInfoBar__2VUd0 {
  display: flex;
  height: 60px;
  padding: 0 20px;
  justify-content: flex-start;
  align-items: center;
  background-color: #262626;
  border-bottom: 1px solid #393939;
  flex-shrink: 0;
  position: relative;
}

@media screen and (min-width: 1025px) {
  .TimelineInfoBar_TimelineInfoBar__2VUd0 {
    height: 81px;
  }
}
.TimelineInfoBar_DateText__2ivdN {
  margin-right: 15px;
}

.TimelineInfoBar_StretchElement__1GPvX {
  flex: 1 0 auto;
}
.pipelineHeartbeatDetails_Pipeline__Wgwnm {
  position: relative;
  display: flex;
  flex-flow: column;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  border: solid 0.5px #6f6f6f;
  border-left: 0;
  background-color: #262626;
}

.pipelineHeartbeatDetails_PipelineHeader__3tbjM {
  position: relative;
  display: flex;
  align-items: center;
  padding: 18px 35px 18px 18px;
}
.pipelineHeartbeatDetails_PipelineHeader__3tbjM::after {
  position: absolute;
  content: "";
  top: -0.5px;
  bottom: -0.5px;
  left: -0.5px;
  width: 4px;
  background-color: #1baa55;
}
.pipelineHeartbeatDetails_PipelineHeaderPartiallyConnected__85ptq::after {
  background-color: #da1e28;
}
.pipelineHeartbeatDetails_PipelineHeaderDisconnected__2PPJa::after {
  background-color: #6f6f6f;
}
.pipelineHeartbeatDetails_PipelineHeaderError__3Yd77::after {
  background-color: #6f6f6f;
}

.pipelineHeartbeatDetails_PipelineButton__1uhG_ {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 10;
  cursor: pointer;
  background-color: transparent;
  border-style: none;
}

.pipelineHeartbeatDetails_CircleIcon__1JUIA {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #ffffff;
  background-color: #1baa55;
}
.pipelineHeartbeatDetails_CircleIconPartiallyConnected__39ChE {
  background-color: #da1e28;
}
.pipelineHeartbeatDetails_CircleIconDisconnected__1vshr {
  background-color: #6f6f6f;
}
.pipelineHeartbeatDetails_CircleIconError__3arBJ {
  background-color: #6f6f6f;
}

.pipelineHeartbeatDetails_Info__Y3CZd {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  text-align: left;
}
.pipelineHeartbeatDetails_InfoSegments__1I_gX {
  margin-bottom: 5px;
  color: #a8a8a8;
  font-size: 0.625rem;
}
.pipelineHeartbeatDetails_InfoName__3QiUp {
  color: #ffffff;
  font-size: 0.875rem;
}

.pipelineHeartbeatDetails_SegmentList__2Di_Y {
  height: 0;
  flex: 1 1 auto;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-flow: row wrap;
  overflow: hidden;
}
.pipelineHeartbeatDetails_SegmentListOpen__HIpL9 {
  border-top: solid 0.5px #6f6f6f;
  border-left: solid 0.5px #6f6f6f;
  height: auto;
  padding: 14px 20px;
}

.pipelineHeartbeatDetails_SegmentItem__1M50J {
  flex: 1 0 100%;
  margin: 6px 0;
  padding: 4px 8px;
  background-color: #da1e28;
  color: #ffffff;
}
.pipelineHeartbeatDetails_SegmentItemDisconnectedText__12_Q7 {
  text-align: center;
}

.pipelineHeartbeatDetails_Toggle__27gCB {
  position: absolute;
  top: calc(50% - 10px);
  right: 20px;
  display: block;
  width: 10px;
  height: 10px;
  color: #ffffff;
  margin-left: auto;
}

@media screen and (min-width: 768px) {
  .pipelineHeartbeatDetails_Pipeline__Wgwnm {
    flex-flow: row;
  }
  .pipelineHeartbeatDetails_PipelineHeader__3tbjM {
    padding: 18px 30px;
  }
  .pipelineHeartbeatDetails_PipelineButton__1uhG_ {
    display: none;
  }
  .pipelineHeartbeatDetails_CircleIcon__1JUIA {
    margin-right: 27px;
  }
  .pipelineHeartbeatDetails_Info__Y3CZd {
    flex: 1 0 auto;
  }
  .pipelineHeartbeatDetails_InfoName__3QiUp {
    font-size: inherit;
  }
  .pipelineHeartbeatDetails_SegmentList__2Di_Y {
    height: auto;
    padding: 10px 30px 10px 0;
    border-style: none;
    justify-content: flex-end;
  }
  .pipelineHeartbeatDetails_SegmentItem__1M50J {
    flex: 0 0 auto;
    margin-left: 12px;
    margin-top: 6px;
    margin-bottom: 6px;
  }
  .pipelineHeartbeatDetails_Toggle__27gCB {
    display: none;
  }
}
.HeartbeatPage_HeartbeatsList__2XDOU {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
}

.HeartbeatPage_HeartbeatsTitle__1RQOa {
  margin: 0;
}

@media screen and (min-width: 768px) {
  .HeartbeatPage_HeartbeatsList__2XDOU {
    padding: 30px;
  }
}
.ReportRow_Report__1KX27 {
  list-style: none;
  background-color: #262626;
}

.ReportRow_Text__2dkCy {
  margin: 0;
  white-space: nowrap;
}

.ReportRow_Buttons__1_lrW {
  display: flex;
  justify-content: flex-end;
  width: 200px;
}

.ReportRow_Button__3mMV_ {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.ReportRow_Main__3gNP_ {
  display: flex;
  padding: 16px 20px 10px;
  border-bottom: 1px solid #525252;
  align-items: center;
}

.ReportRow_Name__q4YD2 {
  margin-left: 20px;
  flex: 1 1;
  min-width: 100px;
}

.ReportRow_Date__2ZUKE {
  margin-right: 32px;
}

.ReportRow_MobileDetails__19z9e {
  display: block;
}

.ReportRow_Details__eu_zW {
  display: flex;
  padding: 10px 20px 16px;
}

.ReportRow_DesktopDetails__1L1ik {
  display: none;
}

.ReportRow_Title__NdNw2 {
  font-size: 0.75rem;
  line-height: 1.125rem;
  letter-spacing: 0.14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 1em;
}

@media screen and (min-width: 768px) {
  .ReportRow_Main__3gNP_ {
    border: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 20px;
  }
  .ReportRow_Details__eu_zW {
    padding: 0;
  }
  .ReportRow_MobileDetails__19z9e {
    display: none;
  }
  .ReportRow_DesktopDetails__1L1ik {
    display: grid;
    grid-template-columns: auto auto;
  }
  .ReportRow_Type__13CES {
    margin-right: 32px;
    width: 100px;
  }
  .ReportRow_Date__2ZUKE,
  .ReportRow_Type__13CES {
    display: flex;
    flex-direction: column;
  }
}
@media screen and (min-width: 1025px) {
  .ReportRow_Main__3gNP_ {
    padding: 27px 27px 27px 53px;
  }
  .ReportRow_Title__NdNw2 {
    font-size: 1rem;
    line-height: 1.375rem;
    letter-spacing: 0.18px;
  }
  .ReportRow_Text__2dkCy {
    font-size: 1rem;
    line-height: 1.375rem;
    letter-spacing: 0.18px;
  }
  .ReportRow_Date__2ZUKE,
  .ReportRow_Type__13CES {
    margin-right: 83px;
  }
}
.Modal_Modal__2x8O8 {
  position: fixed;
  background: #262626;
  outline: none;
  width: 100%;
}

.Modal_Open__3dIo- {
  overflow: hidden;
}

.Modal_Overlay__uy01g {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 40px 0;
  z-index: 999;
}

@media screen and (min-width: 1025px) {
  .Modal_Modal__2x8O8 {
    margin-left: auto;
    margin-right: auto;
  }
  .Modal_Overlay__uy01g {
    align-items: center;
  }
}
.FileDetails_FileDetails__dfJdD {
  background-color: #262626;
  padding: 15px 12px;
  margin-bottom: 20px;
}

.FileDetails_FileTitle__OyECf {
  margin-bottom: 15px;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.FileDetails_File__djKNi {
  display: none;
}

.FileDetails_Date__2zqYF {
  margin-bottom: 15px;
}

.FileDetails_Text__a22sg {
  margin: 0;
}

@media screen and (min-width: 1025px) {
  .FileDetails_Details__1t2uA {
    display: flex;
  }
  .FileDetails_Date__2zqYF {
    margin-right: 50px;
    margin-bottom: 0;
  }
  .FileDetails_File__djKNi {
    display: block;
    margin-right: 30px;
  }
  .FileDetails_FileTitle__OyECf {
    margin-bottom: 27px;
  }
  .FileDetails_FileDetails__dfJdD {
    padding: 30px;
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
  }
}
.Alert_Alert__cWXzF {
  color: #da1e28;
  border: 1px solid #da1e28;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  padding: 12px;
  margin-bottom: 9px;
}
.Alert_AlertSuccess__2jL8q {
  color: #1baa55;
  border-color: #1baa55;
}
.Alert_AlertText__1YXym {
  margin-left: 12px;
}
.DeleteModal_DeleteModal__2zu5Q {
  width: calc(100% - 70px);
  margin: 0 auto;
  padding: 30px 18px;
  top: 85px;
}

.DeleteModal_ModalTitle__2SlPM {
  margin-bottom: 20px;
}

.DeleteModal_Confirm__3nKqc {
  margin-bottom: 25px;
}

.DeleteModal_Text__1RDo6 {
  margin: 3px 0 0;
}

.DeleteModal_Buttons__3xyc- {
  display: flex;
}

.DeleteModal_Button__1Y6P8 {
  flex: 1 1;
  margin-right: 10px;
}
.DeleteModal_Button__1Y6P8:last-of-type {
  margin-right: 0;
}

.DeleteModal_Alert__34koe {
  margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
  .DeleteModal_DeleteModal__2zu5Q {
    max-width: 565px;
  }
}
@media screen and (min-width: 1025px) {
  .DeleteModal_DeleteModal__2zu5Q {
    padding: 30px;
    top: 255px;
    margin-bottom: 30px;
  }
  .DeleteModal_Buttons__3xyc- {
    justify-content: flex-end;
  }
  .DeleteModal_Button__1Y6P8 {
    flex: none;
  }
}
.ReportsPage_List__9zmPn {
  margin: 0 20px;
  padding: 20px 0 10px;
}

.ReportsPage_ListItem__YpAXj {
  margin-bottom: 10px;
}

.ReportsPage_Empty__Vx1Pl {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ReportsPage_Content__315tz {
  display: flex;
  flex-direction: column;
  height: 100%;
}

@media screen and (min-width: 1025px) {
  .ReportsPage_List__9zmPn {
    margin: 20px;
    padding: 0 0 10px;
  }
}
.ReportDetailsPage_Content__3fxmM {
  display: flex;
  flex-direction: column;
  margin: 0;
}

.ReportDetailsPage_Preview__1KeeZ {
  display: flex;
  padding: 0;
  border-top: 1px solid #525252;
  background-color: #262626;
  flex-direction: row;
  justify-content: center;
}

.ReportDetailsPage_PreviewImage__2_kQD {
  max-width: 800px;
  width: calc(100% - 100px);
}

.ReportDetailsPage_Iframe__3AZ9S {
  max-width: 800px;
  width: calc(100% - 100px);
  border: 0;
  height: calc(100vh - 330px);
}

@media screen and (min-width: 768px) {
  .ReportDetailsPage_Content__3fxmM {
    margin: 20px 0 0;
  }
  .ReportDetailsPage_Iframe__3AZ9S {
    height: calc(100vh - 300px);
  }
}
@media screen and (min-width: 1025px) {
  .ReportDetailsPage_Content__3fxmM {
    margin: 20px 0 0;
  }
  .ReportDetailsPage_Iframe__3AZ9S {
    height: calc(100vh - 210px);
  }
}
.ReportsRoute_ButtonWrap__KtiE- {
  flex: 1 1;
  display: flex;
  justify-content: flex-end;
}

@media screen and (min-width: 1025px) {
  .ReportsRoute_UploadDesktop__2IIz8 {
    margin-left: 22px;
  }
}
.UploadModal_UploadModal__1AElI {
  width: calc(100% - 70px);
  margin: 0 auto;
  padding: 30px 18px;
  top: 85px;
}

.UploadModal_FileText__Q2C_Z {
  margin: 0;
  margin-bottom: 10px;
}

.UploadModal_File__2ksDB {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin-bottom: 20px;
}

.UploadModal_ModalTitle__3kK35 {
  margin-bottom: 29px;
}

.UploadModal_FileName__1oOLa {
  margin: 0 0 3px;
}

.UploadModal_Title__2rDWE {
  margin-bottom: 30px;
}

.UploadModal_Buttons__2D-3R {
  display: flex;
}

.UploadModal_Button__fTG5V {
  flex: 1 1;
  margin-right: 10px;
}
.UploadModal_Button__fTG5V:last-of-type {
  margin-right: 0;
}

.UploadModal_Alert__w62cV {
  color: #da1e28;
  border: 1px solid #da1e28;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  padding: 12px;
  margin-bottom: 9px;
}
.UploadModal_AlertText__1OlFD {
  margin-left: 12px;
}

.UploadModal_FileInput__iL_6f {
  background-color: #393939;
  border: 0;
  color: #ffffff;
}

.UploadModal_Uploading__2PJNs {
  display: flex;
  flex-flow: row;
}

@media screen and (min-width: 768px) {
  .UploadModal_UploadModal__1AElI {
    max-width: 565px;
  }
}
@media screen and (min-width: 1025px) {
  .UploadModal_UploadModal__1AElI {
    max-width: 565px;
    padding: 30px;
    top: 255px;
  }
  .UploadModal_Buttons__2D-3R {
    justify-content: flex-end;
  }
  .UploadModal_Button__fTG5V {
    flex: none;
  }
}
.Avatar_Avatar__2yyCJ {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: block;
}
.EventCount_LinkIconCount__29NJg {
  height: 26px;
  min-width: 26px;
  background-color: #da1e28;
  border-radius: 26px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #ffffff;
  margin: 0;
}

.EventCount_Bounce__3LPJJ {
  animation: EventCount_bounce__3rAE- 1s infinite 0s;
}

@keyframes EventCount_bounce__3rAE- {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}
.OrganizationCard_Org__H6fNM {
  border: 1px solid #393939;
  margin-bottom: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.OrganizationCard_OrgActive__3s1_g {
  background-color: #ffffff;
}

.OrganizationCard_OrgNameActive__YkdTe {
  color: #262626;
}

.OrganizationCard_Activate__2vJl_ {
  width: 100%;
}

.OrganizationCard_Details__2LLPV {
  display: flex;
  flex-direction: row;
  margin-bottom: 28px;
}

.OrganizationCard_Logo__KJkJU {
  margin-right: 29px;
  display: flex;
  padding-top: 5px;
}

.OrganizationCard_PipelineCount__2VDCq {
  margin-bottom: 10px;
}

.OrganizationCard_ActiveTitle__2O4Vc {
  margin-right: 20px;
}

.OrganizationCard_ActiveStatus__WhtCL {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1px;
}

.OrganizationCard_CountWrapper__3J7c2 {
  margin-top: 5px;
}

.OrganizationCard_Summary__3O3fi {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
}

.OrganizationCard_OrgInfo__3exgE {
  flex-grow: 1;
  margin-left: 29px;
}

@media screen and (min-width: 1025px) {
  .OrganizationCard_Summary__3O3fi {
    justify-content: safe;
  }
  .OrganizationCard_Org__H6fNM {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 90px;
  }
  .OrganizationCard_OrgInfo__3exgE {
    display: flex;
    flex-direction: row;
  }
  .OrganizationCard_Activate__2vJl_ {
    width: unset;
  }
  .OrganizationCard_Headings__2t7Tj {
    width: 100%;
    max-width: 400px;
  }
  .OrganizationCard_Details__2LLPV {
    margin-bottom: 0;
    flex-grow: 1;
  }
  .OrganizationCard_PipelineCount__2VDCq {
    margin-bottom: 0;
  }
  .OrganizationCard_Logo__KJkJU {
    padding-top: 0;
    align-items: center;
  }
  .OrganizationCard_EventCount__jRehO {
    align-self: center;
    margin-right: 40px;
  }
  .OrganizationCard_Status__2xT8C {
    width: 190px;
  }
}
.OrganizationsPage_Content__3v0k3 {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.OrganizationsPage_OrgList__3LJk7 {
  margin: 0 20px;
  overflow-y: auto;
  flex-grow: 1;
  height: 100%;
}

.OrganizationsPage_CompanyLogout__115-C {
  background-color: #393939;
  width: 100%;
}
.OrganizationsPage_CompanyLogoutContent__14fmw {
  padding: 22px 0;
  display: flex;
  align-items: center;
}

.OrganizationsPage_CompanyInfo__N7OOl {
  margin-left: 16px;
  flex-grow: 1;
}

.OrganizationsPage_Avatar__33ldp {
  margin-left: 20px;
}

.OrganizationsPage_Logout__3E6eE {
  margin-right: 20px;
}

@media screen and (min-width: 1025px) {
  .OrganizationsPage_OrgList__3LJk7 {
    margin-top: 0;
    min-height: unset;
    margin-bottom: 100px;
  }
  .OrganizationsPage_CompanyLogout__115-C {
    bottom: 0;
    position: fixed;
    width: calc(100% - 77px);
  }
  .OrganizationsPage_CompanyInfo__N7OOl {
    margin-left: 30px;
  }
  .OrganizationsPage_Avatar__33ldp {
    margin-left: 40px;
  }
  .OrganizationsPage_Logout__3E6eE {
    margin-right: 40px;
  }
}
.TabbedContent_TabWrapper__3VCJB {
  position: relative;
  width: 100%;
  /* stylelint-disable function-calc-no-invalid */
  height: calc(100% - 44px - 62px);
  /* stylelint-enable function-calc-no-invalid */
}
@media screen and (min-width: 1025px) {
  .TabbedContent_TabWrapper__3VCJB {
    height: 100%;
    overflow: hidden;
  }
}

.TabbedContent_TabbedContent__6_6pz {
  width: 100%;
  background-color: #262626;
  display: flex;
}

.TabbedContent_Tab__2mbwF {
  display: none;
}

.TabbedContent_TabButton__3LiS8 {
  position: relative;
  height: 53px;
  margin-bottom: -1px;
  background-color: #262626;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  padding: 15px 20px;
  border: 0;
  color: #ffffff;
  flex-basis: 50%;
  text-align: center;
  border-bottom: solid 1px #393939;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media screen and (min-width: 1025px) {
  .TabbedContent_TabButton__3LiS8 {
    border-bottom: 0;
    flex-basis: auto;
    font-size: 14px;
    min-width: 195px;
  }
}

.TabbedContent_TabButtonSelected__1aWt5 {
  border-bottom: 0.25em solid #0062ff;
  z-index: 1;
  background-color: #393939;
  overflow: visible;
  width: auto;
}

.TabbedContent_TabPanel__1PCY1 {
  display: none;
}

.TabbedContent_TabPanelSelected__2HVhN {
  display: block;
  height: 100%;
  width: 100%;
}

.TabbedContent_Tabs__2UZJx {
  display: flex;
  width: 100%;
}
.CommentBox_Wrapper__1M74- {
  position: relative;
  display: flex;
  flex-flow: column;
  padding: 30px 20px;
  flex: 1 1 350px;
  background-color: #161616;
}

.CommentBox_LogForm__jMYyx {
  display: flex;
  flex-flow: row;
  margin-bottom: 10px;
}

.CommentBox_LogLabel__2-Kqf {
  position: relative;
  flex: 1 0 auto;
}
.CommentBox_LogLabel__2-Kqf::before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 0;
  top: 30px;
  right: 0;
  height: 3px;
  width: 100%;
  background-color: #8d8d8d;
}

.CommentBox_SendButton__c9v7V {
  margin: 28px 0 0 10px;
}

.CommentBox_LogFade__20L8q {
  position: relative;
  height: 100%;
  overflow: hidden;
}
.CommentBox_LogFade__20L8q::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 20px;
  height: 10px;
  z-index: 10;
  background-image: linear-gradient(to top, rgba(22, 22, 22, 0), #161616);
}
.CommentBox_LogFade__20L8q::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 20px;
  height: 10px;
  z-index: 10;
  background-image: linear-gradient(to bottom, rgba(22, 22, 22, 0), #161616);
}

.CommentBox_LogScroll__om1e0 {
  height: 100%;
  overflow-y: auto;
  margin: 0;
}

.CommentBox_LogList__3mDeF {
  position: relative;
  display: flex;
  flex-flow: column;
  padding: 5px 20px 5px 0;
}

.CommentBox_LogEntry__2OaBu {
  display: flex;
  flex-flow: column;
  position: relative;
  padding-left: 30px;
  padding-bottom: 10px;
}
.CommentBox_LogEntry__2OaBu::before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 9px;
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: #393939;
}
.CommentBox_LogEntry__2OaBu:first-child::before {
  top: 10px;
}
.CommentBox_LogEntry__2OaBu:last-child::before {
  height: 10px;
}
.CommentBox_LogEntry__2OaBu:only-child::before {
  display: none;
}
.CommentBox_LogEntryIcon__1qqur {
  position: absolute;
  top: 4px;
  left: 4px;
  width: 11px;
  height: 11px;
  background-color: #8d8d8d;
  border-radius: 50%;
  border: solid 2px #393939;
}
.CommentBox_LogEntryIconSystem__1lxN6 {
  background-color: #8d8d8d;
}
.CommentBox_LogEntryIconLow__3g0Ir {
  background-color: #0062ff;
}
.CommentBox_LogEntryIconMedium__MhJnY {
  background-color: #f37113;
}
.CommentBox_LogEntryIconHigh__2AbNq {
  background-color: #da1e28;
}
.CommentBox_LogEntryIconComment__14Oq3 {
  left: 0;
  top: 0;
  color: #262626;
  background-color: #ffffff;
  width: 19px;
  height: 19px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CommentBox_NextLine__3IAN0 {
  color: #8d8d8d;
  transform: translate(0, -4px);
}

@media screen and (min-width: 768px) {
  .CommentBox_Wrapper__1M74- {
    height: 215px;
    padding: 20px 20px 5px 30px;
  }
}
.EventInfo_Wrapper__jdjdC {
  flex: 1 0 350px;
  display: flex;
  flex-flow: column;
  padding: 20px;
  border-bottom: 1px solid #393939;
}

.EventInfo_Row__1DbV4 {
  display: grid;
  grid-template-columns: 130px 1fr;
  grid-template-rows: auto;
}
.EventInfo_Row__1DbV4:not(:last-child) {
  margin-bottom: 5px;
}

.EventInfo_Text__3gRJo {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.EventInfo_Link__3QAXg {
  text-decoration: underline;
  text-underline-position: under;
}

.EventInfo_Wrap__1nZBN {
  white-space: pre-wrap;
}

.EventInfo_Divider__1nCh2 {
  width: 100%;
  border: 0;
  border-top: 1px solid #393939;
  margin: 15px 0 20px;
}

@media screen and (min-width: 768px) {
  .EventInfo_Wrapper__jdjdC {
    background-color: #262626;
    padding: 20px 30px 20px 40px;
    border-left: 1px solid #393939;
    border-right: 1px solid #393939;
  }
}
@media screen and (min-width: 1200px) {
  .EventInfo_Wrapper__jdjdC {
    border: 0;
  }
}
.Header_Header__3VDGV {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-bottom: solid 1px #393939;
}

.Header_Type__2WmE_ {
  margin: 0 0 0 10px;
  text-transform: capitalize;
  color: #ffffff;
}

.Header_EventId__nx6Hi {
  margin: 0 0 0 34px;
  color: #ffffff;
}

.Header_TitleGroup__1Um9I {
  padding: 10px 0;
}
.Header_TitleGroup__1Um9I .Header_Type__2WmE_,
.Header_TitleGroup__1Um9I .Header_EventId__nx6Hi {
  align-self: baseline;
}

.Header_Icon__34EP3 {
  display: flex;
}

.Header_Button__2CXs7:not(:last-child) {
  margin-right: 10px;
}
.Header_ButtonGroup__vqvxC {
  display: flex;
  flex-flow: row nowrap;
  padding: 10px 0 10px 10px;
  position: relative;
}
.Header_ButtonShare__1aoDn {
  display: none;
}

.Header_ButtonMenu__3iIVj {
  background-color: #161616;
  margin-right: 10px;
  color: #ffffff;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.Header_SecondaryButtons__2G7wy {
  display: none;
}

.Header_TitleWrapper__28vXg {
  display: flex;
  align-items: center;
}

@media screen and (min-width: 768px) {
  .Header_ButtonMenu__3iIVj {
    display: none;
  }
  .Header_ButtonShare__1aoDn {
    display: flex;
  }
  .Header_SecondaryButtons__2G7wy {
    display: flex;
    margin-right: 10px;
  }
  .Header_ButtonTracking__3KVSJ {
    margin-right: 10px;
  }
}
@media screen and (min-width: 1200px) {
  .Header_Header__3VDGV {
    display: flex;
    padding: 10px 0;
  }
  .Header_TitleGroup__1Um9I {
    flex: 1 0 auto;
    flex-flow: row wrap;
    display: flex;
    align-items: center;
    padding: 0;
  }
  .Header_ButtonGroup__vqvxC {
    padding: 0;
  }
  .Header_Icon__34EP3 {
    margin-left: 5px;
  }
  .Header_EventId__nx6Hi {
    margin: 0 0 0 10px;
  }
}
.Header_Dropdown__wfpIc {
  background-color: #262626;
  list-style-type: none;
  padding: 0;
  margin: 0;
  min-width: 100%;
  border: 1px solid #525252;
}
.Header_Dropdown__wfpIc:hover {
  border-color: #8d8d8d;
}

.Header_DropdownItem__3Eev8 {
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.16px;
  transition-property: background-color;
  transition-duration: 120ms;
  transition-timing-function: cubic-bezier(0, 1.08, 1, 1);
  padding: 10px;
  height: 40px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-content: center;
  border-bottom: 1px solid #525252;
}
.Header_DropdownItem__3Eev8:last-child {
  border-bottom: 0;
}
.Header_DropdownItem__3Eev8:hover {
  border: 0;
  outline: 1px solid #8d8d8d;
}

.Header_DropdownMainContent__jPznG {
  display: flex;
  flex-grow: 1;
  max-width: 100%;
  overflow: hidden;
  align-items: center;
}
@media screen and (min-width: 768px) {
  .Header_DropdownMainContent__jPznG {
    display: none;
  }
}

.Header_PigLinkButton__2hjzu {
  display: none;
}
@media screen and (min-width: 768px) {
  .Header_PigLinkButton__2hjzu {
    display: flex;
  }
}

.Header_IconWrapper__4VlAq {
  margin-right: 10px;
  display: flex;
}

.Header_DropdownLabel__1ezfs {
  white-space: nowrap;
  margin: 0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Header_DropdownButton__203y- {
  background: transparent;
  border: 0;
  color: #ffffff;
}

.Header_MobileMenu__27ii- {
  position: absolute;
  top: 45px;
  right: 42px;
  min-width: 200px;
  z-index: 1;
  display: none;
}
.Header_MobileMenuOpen__1B3-e {
  display: block;
}
.Cluster_Icon__2LJwd {
  stroke: #8d8d8d;
  fill: #161616;
  color: #ffffff;
}
.Cluster_IconButton__My8L6 {
  cursor: pointer;
  color: transparent;
}
.Cluster_IconButton__My8L6:focus {
  outline: none;
  color: #ffffff;
}
.Cluster_IconCircle__UrwmB {
  stroke: #8d8d8d;
  fill: #161616;
  color: #ffffff;
}
.Cluster_IconCount__wuV0G {
  stroke: #ffffff;
  fill: #ffffff;
  stroke-width: 1px;
}
.Cluster_IconCountOpen__2GNSt {
  font-size: 0.8125rem;
  stroke-width: 1px;
}

.Cluster_TextNode__2atXB {
  fill: #8d8d8d;
  stroke-width: 1px;
  text-transform: capitalize;
  font-size: 0.625rem;
  transition: opacity 0.2s ease-in-out;
  opacity: 1;
}

.Cluster_FadeOut__aecKY {
  opacity: 0;
}
.SchematicMap_Container__2THnx {
  position: relative;
  width: 100%;
  height: 210px;
  padding: 20px 20px 15px 40px;
}

.SchematicMap_SchematicMap__1cYp5 {
  width: 100%;
  background-color: #262626;
  overflow: visible;
  height: 100%;
}

.SchematicMap_YAxis__1EDQM {
  color: #8d8d8d;
  font-size: 0.75rem;
  text-transform: capitalize;
  transform: translate(70px, 0);
}

.SchematicMap_Line__Brn6u {
  stroke: #8d8d8d;
  color: #8d8d8d;
  stroke-width: 1;
  font-size: 0.625rem;
}
.SchematicMap_LineEvents__3IZK4 {
  transform: translate(70px, 0);
}
.SchematicMap_LinePosts__2sRVF {
  font-size: 13px;
  transform: translate(70px, 70px);
}
.SchematicMap_LinePostsText__1XAsJ {
  stroke-width: 0.5;
}
.SchematicMap_LinePostsBlue__eiHDL {
  font-size: 13px;
  color: #0062ff;
  transform: translate(70px, 70px);
}
.SchematicMap_LinePostsBlueText__1OJHJ {
  stroke-width: 0.5;
}
.SchematicMap_LinePostsTop__1F51X {
  transform: translate(70px, 52px);
  font-size: 9px;
}
.SchematicMap_LinePostsTopText__1t3Wr {
  stroke-width: 0.35;
}
.SchematicMap_LinePostsBot__1o4bA {
  transform: translate(70px, 88px);
  font-size: 9px;
}
.SchematicMap_LinePostsBotText__2YodW {
  stroke-width: 0.35;
  font-size: 11px;
}
.SchematicMap_LineAssets__R59Q5 {
  transform: translate(70px, 0);
}

.SchematicMap_IconGroup__AIUJb {
  transform: translate(70px, 0);
}

.SchematicMap_Icon__3ixLC {
  stroke: #8d8d8d;
}
.SchematicMap_IconEvent__nLHJc {
  color: #ffffff;
}
.SchematicMap_IconEventLow__1PdXV {
  fill: #0062ff;
}
.SchematicMap_IconEventMedium__27s0n {
  fill: #f37113;
}
.SchematicMap_IconEventHigh__1vK_J {
  fill: #da1e28;
}
.SchematicMap_IconAsset__21Dwc {
  fill: #161616;
  color: #ffffff;
}

.SchematicMap_EventFeaturedLine__sPEAZ {
  stroke: #8d8d8d;
  fill: #8d8d8d;
  stroke-width: 1px;
  stroke-dasharray: 3px;
}

.SchematicMap_EventText__3gL-5 {
  stroke: #8d8d8d;
  fill: #8d8d8d;
  stroke-width: 0.2;
  font-size: 0.75rem;
  text-transform: capitalize;
  opacity: 0;
}
.SchematicMap_EventTextFocus__3rBqe {
  opacity: 1;
}

.SchematicMap_AssetText__faEVl {
  stroke: #8d8d8d;
  fill: #8d8d8d;
  stroke-width: 0.2;
  font-size: 0.625rem;
  text-transform: capitalize;
  opacity: 0;
}
.SchematicMap_AssetTextFocus__ERE3y {
  opacity: 1;
}

.SchematicMap_Fadable__3ruHN {
  transition: opacity 0.4s ease-out;
}

.SchematicMap_FadeOut__3sdMR {
  opacity: 0;
}

.SchematicMap_FadeOutXAxis__2Z9tY {
  opacity: 0;
}
.SchematicMap_FadeOutXAxis__2Z9tY:last-child {
  opacity: 1;
}
.EventSchematicMap_Wrapper__2k6IV {
  height: 206px;
  width: 100%;
  background-color: #262626;
}
.EventDetailMap_Wrapper__7AHLg {
  min-height: 450px;
  width: 100%;
  background-color: #262626;
}
.EventSignatureImage_Title__wyJT7 {
  margin-left: 60px;
  margin-bottom: 25px;
}

.EventSignatureImage_Content__2X2fn {
  display: flex;
  flex-flow: column;
  padding: 30px 20px;
}

.EventSignatureImage_SignatureImage__1ubtQ {
  display: block;
  width: 85%;
  margin: auto;
  height: auto;
}

@media screen and (min-width: 768px) {
  .EventSignatureImage_Content__2X2fn {
    padding: 30px 60px;
  }
}
.SignatureCard_SignatureCard__3O3vy {
  display: flex;
  flex-flow: column;
  padding: 0;
  background-color: #262626;
}
.SignatureCard_SignatureCard__3O3vy:first-child {
  border-top-style: none;
}
.SignatureCard_SignatureCard__3O3vy:last-child {
  border-bottom-style: none;
}

.SignatureCard_Border__3R_Er {
  border-bottom: solid 1px #393939;
  border-top: solid 1px #393939;
}
.SignatureCard_Border__3R_Er + .SignatureCard_Border__3R_Er {
  border-top-style: none;
}
.EventSignatureAcoustic_Name__2lHEp {
  margin-bottom: 25px;
}

.EventSignatureAcoustic_Content__OI4gK {
  display: flex;
  flex-flow: column;
  padding: 30px 20px;
}

@media screen and (min-width: 768px) {
  .EventSignatureAcoustic_Content__OI4gK {
    padding: 30px 60px;
  }
}
.WaveSurferWrapper_WrapperContainer__kPaAB {
  display: flex;
  flex-flow: column;
}

.WaveSurferWrapper_ControlsRow__JgJDw {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.WaveSurferWrapper_LeftControls__1a7WH {
  display: flex;
  flex-flow: row;
}

.WaveSurferWrapper_AudioControlButton__3wiff {
  padding: 0;
  width: 40px;
  height: 40px;
}

.WaveSurferWrapper_ElapsedTime__1MYew {
  margin-left: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.EventSignatureFile_Content__1jNRl {
  display: flex;
  flex-flow: column;
  padding: 30px 20px;
}

.EventSignatureFile_SignatureCardGroup___n0Nv {
  display: flex;
  flex-flow: row;
}

.EventSignatureFile_SignatureField__2HF67 {
  display: flex;
  flex-flow: column;
  margin-right: 46px;
}
.EventSignatureFile_SignatureFieldValue__pr-jL {
  margin: 0;
}

.EventSignatureFile_FileNameGroup__19t0n {
  display: flex;
  min-width: 0;
  align-items: center;
}

.EventSignatureFile_FileName__3EUwP {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0 15px;
  line-height: 1;
}

.EventSignatureFile_FileDetailsGroup__3hqRX {
  justify-content: space-between;
  margin: 20px 0;
}

.EventSignatureFile_DownloadButtonGroup__3vgkp {
  flex-grow: 1;
}

.EventSignatureFile_DownloadWrapper__ABxV- {
  width: 100%;
}
.EventSignatureFile_DownloadWrapper__ABxV-:focus {
  outline: solid 1px #ffffff;
  outline-offset: 1px;
}

.EventSignatureFile_DownloadButton__1aNks {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .EventSignatureFile_Content__1jNRl {
    flex-flow: row;
    align-items: center;
    padding: 30px 60px;
    justify-content: space-between;
  }
  .EventSignatureFile_FileNameGroup__19t0n {
    flex-grow: 1;
  }
  .EventSignatureFile_FileDetailsGroup__3hqRX {
    justify-content: start;
    margin: 0;
  }
  .EventSignatureFile_DownloadButtonGroup__3vgkp {
    flex-grow: inherit;
  }
}
.DetailsSection_Section__2R2Mh {
  position: relative;
  display: flex;
  flex-flow: row wrap;
}
.DetailsSection_Section__2R2Mh:first-child {
  border-bottom: solid 1px #393939;
}
.DetailsSection_SectionLast__1BqrQ {
  border-bottom-style: none;
}

.DetailsSection_Title__qZeHl {
  width: 100%;
  padding: 25px 20px 20px;
  font-size: 16px;
}

@media screen and (min-width: 768px) {
  .DetailsSection_Title__qZeHl {
    font-size: 14px;
    padding: 30px 20px 20px 40px;
  }
}
.EventSignatures_SignaturesContainer__3hIuK {
  display: flex;
  flex-flow: column;
  width: 100%;
}
.DeleteModal_DeleteModal__3Sgxh {
  width: calc(100% - 70px);
  margin: 0 auto;
  padding: 30px 18px;
  top: 85px;
  border-top: 5px solid #8d8d8d;
}
.DeleteModal_DeleteModalSuccess__WvdJY {
  border-top-color: #1baa55;
}
.DeleteModal_DeleteModalSuccess__WvdJY .DeleteModal_AlertText__333Ij {
  color: #1baa55;
}
.DeleteModal_DeleteModalError__3qgFY {
  border-top-color: #da1e28;
}
.DeleteModal_DeleteModalError__3qgFY .DeleteModal_AlertText__333Ij {
  color: #da1e28;
}

.DeleteModal_ModalTitle__1BHbX {
  margin-bottom: 20px;
}

.DeleteModal_Confirm__1viW7 {
  margin-bottom: 25px;
}

.DeleteModal_Text__jew6s {
  margin: 3px 0 0;
}

.DeleteModal_Buttons__3fHDx {
  display: flex;
}

.DeleteModal_Button__2g9RO {
  flex: 1 1;
  margin-right: 10px;
}
.DeleteModal_Button__2g9RO:last-of-type {
  margin-right: 0;
}

.DeleteModal_Alert__3RCh2 {
  margin-bottom: 20px;
}

.DeleteModal_Message__3iu-w {
  display: flex;
  align-items: center;
}

@media screen and (min-width: 768px) {
  .DeleteModal_DeleteModal__3Sgxh {
    max-width: 565px;
  }
}
@media screen and (min-width: 1025px) {
  .DeleteModal_DeleteModal__3Sgxh {
    padding: 30px;
    top: 255px;
    margin-bottom: 30px;
  }
  .DeleteModal_Buttons__3fHDx {
    justify-content: flex-end;
  }
  .DeleteModal_Button__2g9RO {
    flex: none;
  }
}
.EventDetails_Content__2xJQS {
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  overflow-y: none;
}

.EventDetails_Fixed__3oRYG {
  width: 100%;
  height: auto;
}

.EventDetails_Scroll__2K6lh {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  overflow-y: auto;
}
.DetailsModal_DetailsModalMask__1T51M {
  display: none;
}

.DetailsModal_DetailsModal__2hCIV {
  z-index: 100;
  position: fixed;
  top: 44px;
  bottom: 62px;
  left: 0;
  right: 0;
  display: flex;
  border: solid 1px #393939;
  width: 100%;
  background-color: #161616;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

@media screen and (min-width: 1025px) {
  .DetailsModal_DetailsModalMask__1T51M {
    z-index: 99;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-style: none;
    display: flex;
    height: 100%;
    width: 100%;
    background-color: #000000;
    animation: DetailsModal_fadeIn__3iQxT 0.1s linear;
    opacity: 0.4;
    padding: 0;
  }
  .DetailsModal_DetailsModalMask__1T51M:focus {
    outline: none;
  }
  .DetailsModal_DetailsModal__2hCIV {
    position: absolute;
    display: flex;
    top: 15px;
    bottom: 0;
    border: solid 1px #393939;
    width: calc(100% - 100px);
    height: auto;
    /* stylelint-disable function-calc-no-invalid */
    max-height: calc(100% - 15px - 15px);
    /* stylelint-enable function-calc-no-invalid */
    background-color: #161616;
    padding: 0 10px 10px;
    margin: 0 auto;
    box-shadow: 0 0 30px 10px rgba(0, 0, 0, 0.2);
  }
  .DetailsModal_DetailsModalShort__3OL__ {
    /* stylelint-disable function-calc-no-invalid */
    max-height: calc(100% - 15px - 15px - 53px);
    /* stylelint-enable function-calc-no-invalid */
  }
}
@keyframes DetailsModal_fadeIn__3iQxT {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.4;
  }
}
.PaginationButtons_Pagination__1USKV {
  background-color: #161616;
  padding: 30px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.PaginationButtons_Buttons__kua-8 {
  height: 24px;
}

.PaginationButtons_Spacer__O_3ZB {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  text-align: center;
  font-size: 12px;
  letter-spacing: 2px;
  display: flex;
  align-items: center;
}

.PaginationButtons_Disabled__1wMoZ {
  opacity: 0.5;
}

.PaginationButtons_Button__1u52B {
  background-color: transparent;
  border: 0;
  color: #ffffff;
  height: 24px;
  width: 24px;
  margin-right: 10px;
  padding: 0;
  cursor: pointer;
}
.PaginationButtons_ButtonIcon__2QmiL {
  background-color: transparent;
  border: 0;
  height: 24px;
  width: 24px;
  padding: 0;
  margin-right: 10px;
  cursor: pointer;
}
.PaginationButtons_Button__1u52B:last-child {
  margin-right: 0;
}
.PaginationButtons_ButtonText__3onGw {
  padding: 0;
  margin: 0;
}
.PaginationButtons_ButtonCurrent__h-yAz {
  background-color: #ffffff;
  color: #161616;
}
.ListDetailsSection_Container__304O0 {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* stylelint-disable function-calc-no-invalid */
  min-height: calc(100% - 62px);
  /* stylelint-enable function-calc-no-invalid */
}
@media screen and (min-width: 768px) {
  .ListDetailsSection_Container__304O0 {
    min-height: 100%;
  }
}

.ListDetailsSection_HeaderWrapper__2jdtL {
  width: 100%;
  height: auto;
  border-style: none;
}

.ListDetailsSection_ListMapWrapper__1E8y6 {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  height: 100%;
  flex-grow: 1;
}
@media screen and (min-width: 768px) {
  .ListDetailsSection_ListMapWrapper__1E8y6 {
    flex-grow: initial;
  }
}
@media screen and (min-width: 1025px) {
  .ListDetailsSection_ListMapWrapper__1E8y6 {
    height: calc(100% - 53px);
  }
}

.ListDetailsSection_List__pejJ1 {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-style: none;
  padding-bottom: 80px;
  padding-top: 10px;
}

.ListDetailsSection_MapModalWrapper__p4Gtr {
  position: absolute;
  background-color: #393939;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.ListDetailsSection_MapContainer__1cj_1 {
  display: none;
}

.ListDetailsSection_PaginationHeading__1C3zT {
  display: block;
  margin-bottom: 10px;
  padding: 10px 25px;
}

@media screen and (min-width: 1025px) {
  .ListDetailsSection_Container__304O0 {
    overflow-y: auto;
    display: grid;
    grid-template-rows: auto 1fr;
    height: 100%;
  }
  .ListDetailsSection_HeaderWrapper__2jdtL {
    border-top: solid 0.25px #393939;
    border-bottom: solid 0.25px #393939;
  }
  .ListDetailsSection_ListMapWrapper__1E8y6 {
    overflow: hidden;
  }
  .ListDetailsSection_List__pejJ1 {
    display: block;
    flex: 1 0 25%;
    overflow-y: auto;
    margin: 0;
    padding-top: 10px;
    min-width: 390px;
    max-width: 600px;
    border-right: solid 0.25px #393939;
  }
  .ListDetailsSection_MapModalWrapper__p4Gtr {
    position: relative;
  }
  .ListDetailsSection_MapContainer__1cj_1 {
    display: flex;
    z-index: 1;
    position: relative;
    background-color: #393939;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}
.PigRun_Header__2vb8k {
  display: flex;
  flex-flow: column;
  color: #ffffff;
  width: 100%;
}

.PigRun_TitleGroup__2P4rD {
  align-items: baseline;
  border-bottom: 1px solid #525252;
  padding-bottom: 15px;
}

.PigRun_InfoGroup__181V- {
  padding-top: 15px;
}

.PigRun_Type__3jJ7E {
  margin: 0 10px 0 0;
  text-transform: capitalize;
}
.PigRun_TypeDark__1nejx {
  color: #8d8d8d;
  text-transform: capitalize;
}

.PigRun_Card__2vC4q {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  height: auto;
  padding: 20px 15px 20px 20px;
  margin-bottom: 10px;
  border: solid 0.25px #393939;
  border-left-style: none;
  background-color: #262626;
  text-align: unset;
  transition: background-color 0.3s ease;
}
.PigRun_Card__2vC4q::after {
  content: "";
  position: absolute;
  top: -0.25px;
  left: 0;
  bottom: -0.25px;
  width: 4px;
  transition: width 0.3s ease;
  background-color: #0062ff;
}
.PigRun_Card__2vC4q:focus {
  border-color: #ffffff;
  outline: none;
}
.PigRun_Card__2vC4q:hover {
  background-color: #101010;
}
.PigRun_CardMedium__26fCR {
  color: #f37113;
}
.PigRun_CardMedium__26fCR::after {
  background-color: #f37113;
}
.PigRun_CardHigh__2_XcA {
  color: #da1e28;
}
.PigRun_CardHigh__2_XcA::after {
  background-color: #da1e28;
}

.PigRun_PigStatus__3SWyy {
  position: relative;
  padding-left: 15px;
  margin: 0;
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
}
.PigRun_PigStatus__3SWyy::before {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  left: 0;
  top: calc(50% - 4px);
  background-color: #1baa55;
}
.PigRun_PigStatusRed__2s3cN::before {
  background-color: #da1e28;
}
.PigRun_PigStatusYellow__29VZ8::before {
  background-color: #f37113;
}

@media screen and (min-width: 1025px) {
  .PigRun_Card__2vC4q {
    padding: 20px 20px 20px 25px;
  }
}
.PigRun_CompletedCard__2xvqn {
  display: block;
  background-color: #262626;
  padding: 25px 20px;
  margin: 0 0 5px;
}

.PigRun_InfoGroupWrapper__tPg1o {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.PigRun_InfoGroupWrapper__tPg1o .PigRun_InfoGroup__181V- {
  padding: 0;
  flex-shrink: 1;
}
.PigRun_InfoGroupWrapper__tPg1o .PigRun_Type__3jJ7E {
  margin: 0;
}

.PigRun_IconContainer__271It {
  margin: 0 30px;
}

.PigRun_Updates__VYmrQ {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 0;
}
.PigRun_Updates__VYmrQ .PigRun_Type__3jJ7E {
  margin: 0;
}
.CompletedRuns_TableWrapperDesktop__1YXUK {
  padding: 10px;
  display: none;
}
@media screen and (min-width: 768px) {
  .CompletedRuns_TableWrapperDesktop__1YXUK {
    display: block;
  }
}
.CompletedRuns_TableWrapperMobile__3ZEpq {
  margin-top: 20px;
  padding: 0 20px;
}
@media screen and (min-width: 768px) {
  .CompletedRuns_TableWrapperMobile__3ZEpq {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .CompletedRuns_NoMatchWrapper__27ZWr {
    height: calc(100% - 53px - 108px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.CompletedRuns_DownloadLink__ma3Pj {
  margin: 20px;
}
@media screen and (min-width: 768px) {
  .CompletedRuns_DownloadLink__ma3Pj {
    margin: 10px;
    display: inline-flex;
  }
}

.CompletedRuns_LinkWrapper__3P9XK {
  display: inline-block;
  width: 100%;
}

.CompletedRuns_FilterBarWrapper__1fAnK {
  background-color: #262626;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-top: 1px solid #393939;
  border-bottom: 1px solid #393939;
  display: none;
  position: relative;
}
@media screen and (min-width: 1025px) {
  .CompletedRuns_FilterBarWrapper__1fAnK {
    display: flex;
  }
}

.CompletedRuns_FilterBarInner__2y1Rv {
  display: flex;
  align-items: flex-end;
}
.CompletedRuns_FilterBarInner__2y1Rv:first-child {
  max-width: calc(100% - 280px);
}

.CompletedRuns_FilterContainer__69ew- {
  margin-right: 15px;
}
.CompletedRuns_FilterContainer__69ew-:first-child {
  max-width: unset;
}
.CompletedRuns_FilterContainerQuarter__1UKoK {
  max-width: calc(25% - 15px);
}
@media screen and (min-width: 1680px) {
  .CompletedRuns_FilterContainerQuarter__1UKoK {
    max-width: unset;
  }
}
.CompletedRuns_FilterContainerThird__38bZV {
  max-width: calc(33% - 15px);
}
@media screen and (min-width: 1680px) {
  .CompletedRuns_FilterContainerThird__38bZV {
    max-width: unset;
  }
}

.CompletedRuns_PaginationText__2KWeh {
  margin: 0 30px 10px 0;
  white-space: nowrap;
}
.Table_TableWrapper__MzqY9 {
  overflow-y: auto;
  border: 1px solid #393939;
}

.Table_Table__1VBm7 {
  border-collapse: collapse;
  width: 100%;
  background: #161616;
}

.Table_TableRow__3vq7f {
  width: 100%;
  padding: 0;
}
.Table_TableRow__3vq7f:not(:last-child) {
  border-bottom: 1px solid #393939;
}
.Table_TableRow__3vq7f:hover {
  background-color: #101010;
}

.Table_TableCell__wK2E0 {
  padding: 20px 10px;
  border-collapse: collapse;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.16px;
  min-height: 56px;
  text-transform: capitalize;
  vertical-align: top;
  white-space: pre-line;
  z-index: 0;
  cursor: pointer;
}

.Table_TableHeader__1XzKC {
  padding: 20px 10px;
  text-align: left;
  font-weight: 600;
  text-transform: capitalize;
  vertical-align: top;
  min-height: 56px;
  background: #262626;
  position: sticky;
  top: 0;
  z-index: 1;
}

.Table_TableCaption__2YDWF {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.DayPicker__hidden {
  visibility: visible;
}

.DayPicker_transitionContainer__horizontal {
  min-height: 285px;
}

.Calendar,
.CalendarDay,
.CalendarMonth,
.CalendarMonthGrid,
.DayPicker,
.DayPickerNavigation_button__default {
  background: #262626;
  border: 0;
}

.DayPickerNavigation_button__default:hover, .DayPickerNavigation_button__default:focus, .DayPickerNavigation_button__default:active {
  background: #393939;
  border-color: #ffffff;
  border-radius: 0;
}
.DayPickerNavigation_button__disabled {
  border: 0;
  color: #393939;
}
.DayPickerNavigation_button__disabled:hover {
  border: 0;
}
.DayPickerNavigation_svg__horizontal {
  fill: #ffffff;
}

.DayPicker_weekHeader_li,
.CalendarMonth_caption {
  font-size: 0.875rem;
}

.DayPicker_weekHeader_li {
  color: #8d8d8d;
}

.DayPicker_weekHeader {
  top: 56px;
}

/* stylelint-disable selector-max-type */
.CalendarMonth_caption {
  color: #ffffff;
}
.CalendarMonth_caption strong {
  font-weight: 400;
}

/* stylelint-enable selector-max-type */
.CalendarDay {
  color: #ffffff;
}
.CalendarDay:active, .CalendarDay:hover, .CalendarDay:focus {
  border: 0;
  color: #ffffff;
  background: #393939;
  border-radius: 50%;
}
.CalendarDay__hovered_span {
  background: #393939;
}
.CalendarDay__hovered_span + .CalendarDay:hover {
  border-radius: 0 50% 50% 0;
}
.CalendarDay__blocked_out_of_range {
  background: transparent;
  color: #8d8d8d;
}
.CalendarDay__blocked_out_of_range:hover {
  background: transparent;
  color: #8d8d8d;
}

.CalendarDay__hovered_span.CalendarDay__firstDayOfWeek:hover {
  border-radius: 0 50% 50% 0;
}

.CalendarDay__selected {
  /** creates a bunch of 1px shadows each offset by one more pixel than the last
   *  this is used to create a long shadow off of an element while preserving the
   *  border-radius on either end.
   *    $size: how long the shadow should be (sans unit please)
   *    $direction: the direction the shadow should stretch into: 1 = right, -1 = left
   */
  position: relative;
  color: #ffffff;
  background: #0062ff;
}
.CalendarDay__selected:active, .CalendarDay__selected:hover, .CalendarDay__selected:focus {
  color: #ffffff;
  background: #0062ff;
}
.CalendarDay__selected_start {
  border-radius: 50% 0 0 50%;
}
.CalendarDay__selected_start:hover {
  box-shadow: 1px 0 0 0 #0062ff, 2px 0 0 0 #0062ff, 3px 0 0 0 #0062ff, 4px 0 0 0 #0062ff, 5px 0 0 0 #0062ff, 6px 0 0 0 #0062ff, 7px 0 0 0 #0062ff, 8px 0 0 0 #0062ff, 9px 0 0 0 #0062ff, 10px 0 0 0 #0062ff, 11px 0 0 0 #0062ff, 12px 0 0 0 #0062ff, 13px 0 0 0 #0062ff, 14px 0 0 0 #0062ff, 15px 0 0 0 #0062ff, 16px 0 0 0 #0062ff, 17px 0 0 0 #0062ff, 18px 0 0 0 #0062ff, 19px 0 0 0 #0062ff, 20px 0 0 0 #0062ff, 21px 0 0 0 #0062ff, 22px 0 0 0 #0062ff, 23px 0 0 0 #0062ff, 24px 0 0 0 #0062ff, 25px 0 0 0 #0062ff, 26px 0 0 0 #0062ff, 27px 0 0 0 #0062ff, 28px 0 0 0 #0062ff, 29px 0 0 0 #0062ff, 30px 0 0 0 #0062ff, 31px 0 0 0 #0062ff, 32px 0 0 0 #0062ff, 33px 0 0 0 #0062ff, 34px 0 0 0 #0062ff, 35px 0 0 0 #0062ff, 36px 0 0 0 #0062ff, 37px 0 0 0 #0062ff, 38px 0 0 0 #0062ff, 39px 0 0 0 #0062ff, 40px 0 0 0 #0062ff, 41px 0 0 0 #0062ff, 42px 0 0 0 #0062ff, 43px 0 0 0 #0062ff, 44px 0 0 0 #0062ff, 45px 0 0 0 #0062ff, 46px 0 0 0 #0062ff, 47px 0 0 0 #0062ff, 48px 0 0 0 #0062ff, 49px 0 0 0 #0062ff, 50px 0 0 0 #0062ff, inset 0 0 0 2px #0062ff;
  overflow: hidden;
  border-radius: 100%;
  background-color: #064ada;
}
.CalendarDay__selected_start.CalendarDay__selected_end {
  border-radius: 100%;
}
.CalendarDay__selected_start.CalendarDay__selected_end:hover {
  border-radius: 100%;
  box-shadow: inset 0 0 0 2px #0062ff;
}
.CalendarDay__selected_end {
  border-radius: 0 50% 50% 0;
}
.CalendarDay__selected_end:hover {
  box-shadow: -1px 0 0 0 #0062ff, -2px 0 0 0 #0062ff, -3px 0 0 0 #0062ff, -4px 0 0 0 #0062ff, -5px 0 0 0 #0062ff, -6px 0 0 0 #0062ff, -7px 0 0 0 #0062ff, -8px 0 0 0 #0062ff, -9px 0 0 0 #0062ff, -10px 0 0 0 #0062ff, -11px 0 0 0 #0062ff, -12px 0 0 0 #0062ff, -13px 0 0 0 #0062ff, -14px 0 0 0 #0062ff, -15px 0 0 0 #0062ff, -16px 0 0 0 #0062ff, -17px 0 0 0 #0062ff, -18px 0 0 0 #0062ff, -19px 0 0 0 #0062ff, -20px 0 0 0 #0062ff, -21px 0 0 0 #0062ff, -22px 0 0 0 #0062ff, -23px 0 0 0 #0062ff, -24px 0 0 0 #0062ff, -25px 0 0 0 #0062ff, -26px 0 0 0 #0062ff, -27px 0 0 0 #0062ff, -28px 0 0 0 #0062ff, -29px 0 0 0 #0062ff, -30px 0 0 0 #0062ff, -31px 0 0 0 #0062ff, -32px 0 0 0 #0062ff, -33px 0 0 0 #0062ff, -34px 0 0 0 #0062ff, -35px 0 0 0 #0062ff, -36px 0 0 0 #0062ff, -37px 0 0 0 #0062ff, -38px 0 0 0 #0062ff, -39px 0 0 0 #0062ff, -40px 0 0 0 #0062ff, -41px 0 0 0 #0062ff, -42px 0 0 0 #0062ff, -43px 0 0 0 #0062ff, -44px 0 0 0 #0062ff, -45px 0 0 0 #0062ff, -46px 0 0 0 #0062ff, -47px 0 0 0 #0062ff, -48px 0 0 0 #0062ff, -49px 0 0 0 #0062ff, -50px 0 0 0 #0062ff, inset 0 0 0 2px #0062ff;
  overflow: hidden;
  border-radius: 100%;
  background-color: #064ada;
}
.CalendarDay__selected_span {
  color: #ffffff;
  background: #0062ff;
}
.CalendarDay__selected_span:hover {
  overflow: hidden;
  color: #ffffff;
  background-color: #064ada;
  border-radius: 100%;
  box-shadow: 0 0 0 50px #0062ff, inset 0 0 0 2px #0062ff;
}
.CalendarDay__selected_start_in_hovered_span + .CalendarDay__hovered_span:not(.CalendarDay__selected):not(.CalendarDay__selected_span):hover {
  border-radius: 0 50% 50% 0;
}

@media screen and (min-width: 1025px) {
  .DayPickerNavigation {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: auto;
    justify-content: space-between;
    padding: 0 32px;
  }
  .DayPicker_weekHeader {
    top: 50px;
  }
  .CalendarMonth_caption {
    padding-top: 0;
    padding-bottom: 60px;
    line-height: 22px;
  }
}
.DatePicker_FilterWrapper__2pqkU {
  height: 100%;
  display: flex;
  justify-content: center;
}

.DatePicker_Filter__3pEr6 {
  display: block;
  background-color: #262626;
}

.DatePicker_DatePickerToggle__2cgsN {
  display: none;
}

.DatePicker_Buttons__1FToR {
  display: none;
}

.DatePicker_Wrapper__mmTsV {
  display: flex;
  height: 100%;
  justify-content: center;
}

.DatePicker_NavIconWrapperPrev__3ip50 {
  position: absolute;
  left: 30px;
  top: 20px;
  width: 40px;
}
.DatePicker_NavIconWrapperNext__3FDH4 {
  position: absolute;
  right: 6px;
  top: 20px;
  width: 40px;
}

.DatePicker_DatePickerLabel__20Q6u {
  display: none;
}

@media screen and (min-width: 1025px) {
  .DatePicker_FilterWrapper__2pqkU {
    position: relative;
    display: block;
    height: auto;
    z-index: 110;
  }
  .DatePicker_Filter__3pEr6 {
    display: none;
    position: absolute;
    padding: 40px 5px;
    border: 1px solid #525252;
    top: calc(100% - 1px);
  }
  .DatePicker_FilterOpen__3ic4z {
    display: block;
  }
  .DatePicker_DatePickerToggle__2cgsN {
    display: inline;
  }
  .DatePicker_DatePickerLabel__20Q6u {
    display: block;
    margin: 0 0 8px;
  }
  .DatePicker_Buttons__1FToR {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 10px 34px 0;
  }
  .DatePicker_NavIconWrapperPrev__3ip50,
  .DatePicker_NavIconWrapperNext__3FDH4 {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    width: auto;
  }
  .DatePicker_DatePickerWrapper__1BdEM {
    max-width: 100%;
    text-overflow: ellipsis;
  }
}
.CheckboxInput_CheckboxItem__EUUbj {
  flex-basis: 100%;
  display: flex;
  align-items: center;
  white-space: nowrap;
  border-bottom: 1px solid #393939;
  padding: 10px;
}
.CheckboxInput_CheckboxItem__EUUbj:hover {
  background-color: #393939;
  cursor: pointer;
}
.CheckboxInput_CheckboxItemSmall__31D2x {
  height: 32px;
  padding: 5px 10px;
}

.CheckboxInput_Highlighted__WwKjL {
  background-color: #393939;
  cursor: pointer;
}

.CheckboxInput_CheckboxLabel__3TRox {
  padding: 0;
  margin: 0;
  margin-left: 10px;
  text-transform: capitalize;
}
.CheckboxInput_CheckboxLabelWithAltText__2e6Gw {
  margin-right: 40px;
}

.CheckboxInput_CheckboxInput__2MFJC {
  position: absolute;
  z-index: -1;
  opacity: 0;
  margin: 0;
}
.CheckboxInput_CheckboxInput__2MFJC:focus + .CheckboxInput_CheckboxLabel__3TRox {
  outline: 5px auto -webkit-focus-ring-color;
}

.CheckboxInput_CheckboxWrapper__2R9Zh {
  display: flex;
  flex-direction: column;
}

.CheckboxInput_AltTextWrapper__2imLa {
  display: flex;
  margin-left: auto;
}
.MultiSelect_DropdownWrapper__15idy {
  position: relative;
  display: flex;
  flex-direction: column;
}

.MultiSelect_Dropdown__1RKAr {
  position: absolute;
  background-color: #262626;
  z-index: 110;
  list-style-type: none;
  padding: 0;
  top: calc(100% - 1px);
  margin: 0;
  min-width: 250px;
}
@media screen and (min-width: 1025px) {
  .MultiSelect_Dropdown__1RKAr {
    max-height: 460px;
    overflow-y: auto;
  }
}
.MultiSelect_DropdownOpen__3gRlt {
  border: 1px solid #525252;
}
.MultiSelect_Dropdown__1RKAr:hover {
  border-color: #8d8d8d;
}

.MultiSelect_DropdownButtonWrapper__u6Sxv:hover ~ .MultiSelect_Dropdown__1RKAr {
  border-color: #8d8d8d;
}

.MultiSelect_Buttons__2hOUz {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.MultiSelect_TextLink__2JH1H {
  padding: 0 10px;
}

.MultiSelect_Label__2XLCx {
  display: block;
  margin-bottom: 8px;
}
.MobileFilters_Wrapper__McM5c {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex: 0 1 auto;
  background-color: #262626;
}

.MobileFilters_MobileFilterBar__2p5ma {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* stylelint-disable-next-line selector-max-universal */
}
.MobileFilters_MobileFilterBar__2p5ma > * {
  margin-right: 5px;
  flex-basis: 33.333333%;
}
.MobileFilters_MobileFilterBar__2p5ma > :first-child {
  margin-left: 5px;
}

.MobileFilters_FilterButton__1ClHB {
  transition-property: border-color;
  transition-duration: 120ms;
  transition-timing-function: cubic-bezier(0, 1.08, 1, 1);
  display: inline;
  background-color: #262626;
  border: 1px solid #525252;
}
.MobileFilters_FilterButton__1ClHB:hover, .MobileFilters_FilterButton__1ClHB:focus {
  background-color: #262626;
  border: 1px solid #8d8d8d;
  outline: none;
}
.MobileFilters_FilterButtonHasActive__2d0pX {
  background-color: #393939;
}
.MobileFilters_FilterButtonHasActive__2d0pX:hover, .MobileFilters_FilterButtonHasActive__2d0pX:focus {
  background-color: #393939;
}

.MobileFilters_FilterButtonContent__3gjJW {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.MobileFilters_FiltersCount__gFKjA {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: #ffffff;
  color: #000000;
  margin-left: 5px;
}

.MobileFilters_ResultsCount__11buB {
  display: inline;
  text-align: center;
  max-width: 80px;
  align-self: center;
}

.MobileFilters_Overlay__2j02F {
  top: 43px;
  padding: 0;
  background-color: #262626;
  /* stylelint-disable-next-line selector-max-universal */
}
.MobileFilters_Overlay__2j02F > * {
  background-color: transparent;
}

.MobileFilters_ModalControls__m90qT {
  display: flex;
  align-items: center;
  z-index: 10;
  height: 64px;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 20px;
  border-top: 1px solid #525252;
  border-bottom: 1px solid #525252;
}

.MobileFilters_FiltersList__GbuMf {
  max-height: calc(100vh - 108px);
  overflow-y: auto;
  background: transparent;
}

.MobileFilters_Filter__2tfK9 {
  padding: 20px;
  border-bottom: 1px solid #525252;
}

.MobileFilters_Close__1s6t- {
  color: #ffffff;
  background-color: transparent;
  border: 0;
}
.MobileFilters_Close__1s6t-:hover {
  cursor: pointer;
}

.MobileFilters_ShowButton__GjpaO {
  width: calc(100% - 40px);
  height: 48px;
  margin: 20px;
}

@media screen and (min-width: 375px) {
  .MobileFilters_MobileFilterBar__2p5ma {
    /* stylelint-disable-next-line selector-max-universal */
  }
  .MobileFilters_MobileFilterBar__2p5ma > :first-child {
    margin-left: 10px;
  }
  .MobileFilters_MobileFilterBar__2p5ma > * {
    margin-right: 10px;
  }
}
@media screen and (min-width: 1025px) {
  .MobileFilters_Wrapper__McM5c {
    display: none;
  }
}
.TrainRun_Header__2tHmn {
  display: flex;
  flex-flow: column;
  color: #ffffff;
  width: 100%;
}

.TrainRun_TitleGroup__2rBck {
  align-items: baseline;
  border-bottom: 1px solid #525252;
  padding-bottom: 15px;
}

.TrainRun_InfoGroup__eZf86 {
  padding-top: 15px;
}

.TrainRun_Type__jkAkW {
  margin: 0 10px 0 0;
  text-transform: capitalize;
}
.TrainRun_TypeDark__-sm6C {
  color: #8d8d8d;
  text-transform: capitalize;
}

.TrainRun_Card__1XL0y {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  height: auto;
  padding: 20px 15px 20px 20px;
  margin-bottom: 10px;
  border: solid 0.25px #393939;
  border-left-style: none;
  background-color: #262626;
  text-align: unset;
  transition: background-color 0.3s ease;
}
.TrainRun_Card__1XL0y::after {
  content: "";
  position: absolute;
  top: -0.25px;
  left: 0;
  bottom: -0.25px;
  width: 4px;
  transition: width 0.3s ease;
  background-color: #0062ff;
}
.TrainRun_Card__1XL0y:focus {
  border-color: #ffffff;
  outline: none;
}
.TrainRun_Card__1XL0y:hover {
  background-color: #101010;
}
.TrainRun_CardMedium__2mScc {
  color: #f37113;
}
.TrainRun_CardMedium__2mScc::after {
  background-color: #f37113;
}
.TrainRun_CardHigh__20KRK {
  color: #da1e28;
}
.TrainRun_CardHigh__20KRK::after {
  background-color: #da1e28;
}

.TrainRun_TrainStatus__1sfJe {
  position: relative;
  padding-left: 15px;
  margin: 0;
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
}
.TrainRun_TrainStatus__1sfJe::before {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  left: 0;
  top: calc(50% - 4px);
  background-color: #1baa55;
}
.TrainRun_TrainStatusRed__kPXWA::before {
  background-color: #da1e28;
}
.TrainRun_TrainStatusYellow__eHI9y::before {
  background-color: #f37113;
}

@media screen and (min-width: 1025px) {
  .TrainRun_Card__1XL0y {
    padding: 20px 20px 20px 25px;
  }
}
.TrainRun_CompletedCard__32Zgp {
  display: block;
  background-color: #262626;
  padding: 25px 20px;
  margin: 0 0 5px;
}

.TrainRun_InfoGroupWrapper__21kzr {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.TrainRun_InfoGroupWrapper__21kzr .TrainRun_InfoGroup__eZf86 {
  padding: 0;
  flex-shrink: 1;
}
.TrainRun_InfoGroupWrapper__21kzr .TrainRun_Type__jkAkW {
  margin: 0;
}

.TrainRun_IconContainer__Yo322 {
  margin: 0 30px;
}

.TrainRun_Updates__1GoS8 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 0;
}
.TrainRun_Updates__1GoS8 .TrainRun_Type__jkAkW {
  margin: 0;
}
.CompletedRuns_TableWrapperDesktop__vC4VF {
  padding: 10px;
  display: none;
}
@media screen and (min-width: 768px) {
  .CompletedRuns_TableWrapperDesktop__vC4VF {
    display: block;
  }
}
.CompletedRuns_TableWrapperMobile__3kCrn {
  margin-top: 20px;
  padding: 0 20px;
}
@media screen and (min-width: 768px) {
  .CompletedRuns_TableWrapperMobile__3kCrn {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .CompletedRuns_NoMatchWrapper__1Fyg9 {
    height: calc(100% - 53px - 108px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.CompletedRuns_DownloadLink__7-xBl {
  margin: 20px;
}
@media screen and (min-width: 768px) {
  .CompletedRuns_DownloadLink__7-xBl {
    margin: 10px;
    display: inline-flex;
  }
}

.CompletedRuns_LinkWrapper__3WDBs {
  display: inline-block;
  width: 100%;
}

.CompletedRuns_FilterBarWrapper__3S7Sk {
  background-color: #262626;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-top: 1px solid #393939;
  border-bottom: 1px solid #393939;
  display: none;
  position: relative;
}
@media screen and (min-width: 1025px) {
  .CompletedRuns_FilterBarWrapper__3S7Sk {
    display: flex;
  }
}

.CompletedRuns_FilterBarInner__ALEeD {
  display: flex;
  align-items: flex-end;
}
.CompletedRuns_FilterBarInner__ALEeD:first-child {
  max-width: calc(100% - 280px);
}

.CompletedRuns_FilterContainer__zmvqN {
  margin-right: 15px;
}
.CompletedRuns_FilterContainer__zmvqN:first-child {
  max-width: unset;
}
.CompletedRuns_FilterContainerQuarter__37LiK {
  max-width: calc(25% - 15px);
}
@media screen and (min-width: 1680px) {
  .CompletedRuns_FilterContainerQuarter__37LiK {
    max-width: unset;
  }
}
.CompletedRuns_FilterContainerThird__1HuYr {
  max-width: calc(33% - 15px);
}
@media screen and (min-width: 1680px) {
  .CompletedRuns_FilterContainerThird__1HuYr {
    max-width: unset;
  }
}

.CompletedRuns_PaginationText__1vsLD {
  margin: 0 30px 10px 0;
  white-space: nowrap;
}
.MobileFilters_Wrapper__14SyG {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex: 0 1 auto;
  background-color: #262626;
}

.MobileFilters_MobileFilterBar__NFCtZ {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* stylelint-disable-next-line selector-max-universal */
}
.MobileFilters_MobileFilterBar__NFCtZ > * {
  margin-right: 5px;
  flex-basis: 33.333333%;
}
.MobileFilters_MobileFilterBar__NFCtZ > :first-child {
  margin-left: 5px;
}

.MobileFilters_FilterButton__2M20e {
  transition-property: border-color;
  transition-duration: 120ms;
  transition-timing-function: cubic-bezier(0, 1.08, 1, 1);
  display: inline;
  background-color: #262626;
  border: 1px solid #525252;
}
.MobileFilters_FilterButton__2M20e:hover, .MobileFilters_FilterButton__2M20e:focus {
  background-color: #262626;
  border: 1px solid #8d8d8d;
  outline: none;
}
.MobileFilters_FilterButtonHasActive__3mNDT {
  background-color: #393939;
}
.MobileFilters_FilterButtonHasActive__3mNDT:hover, .MobileFilters_FilterButtonHasActive__3mNDT:focus {
  background-color: #393939;
}

.MobileFilters_FilterButtonContent__mpyoV {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.MobileFilters_FiltersCount__1Oir7 {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: #ffffff;
  color: #000000;
  margin-left: 5px;
}

.MobileFilters_ResultsCount__2_eyV {
  display: inline;
  text-align: center;
  max-width: 80px;
  align-self: center;
}

.MobileFilters_Overlay__6HKQI {
  top: 43px;
  padding: 0;
  background-color: #262626;
  /* stylelint-disable-next-line selector-max-universal */
}
.MobileFilters_Overlay__6HKQI > * {
  background-color: transparent;
}

.MobileFilters_ModalControls__2Ut0f {
  display: flex;
  align-items: center;
  z-index: 10;
  height: 64px;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 20px;
  border-top: 1px solid #525252;
  border-bottom: 1px solid #525252;
}

.MobileFilters_FiltersList__1dGIO {
  max-height: calc(100vh - 108px);
  overflow-y: auto;
  background: transparent;
}

.MobileFilters_Filter__p8PhF {
  padding: 20px;
  border-bottom: 1px solid #525252;
}

.MobileFilters_Close__1Lu6O {
  color: #ffffff;
  background-color: transparent;
  border: 0;
}
.MobileFilters_Close__1Lu6O:hover {
  cursor: pointer;
}

.MobileFilters_ShowButton__2be7W {
  width: calc(100% - 40px);
  height: 48px;
  margin: 20px;
}

@media screen and (min-width: 375px) {
  .MobileFilters_MobileFilterBar__NFCtZ {
    /* stylelint-disable-next-line selector-max-universal */
  }
  .MobileFilters_MobileFilterBar__NFCtZ > :first-child {
    margin-left: 10px;
  }
  .MobileFilters_MobileFilterBar__NFCtZ > * {
    margin-right: 10px;
  }
}
@media screen and (min-width: 1025px) {
  .MobileFilters_Wrapper__14SyG {
    display: none;
  }
}
.EventCard_Card__S7WES {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  height: auto;
  padding: 16px;
  margin: 0;
  margin-bottom: 10px;
  border: solid 0.25px #393939;
  border-left-style: none;
  background-color: #262626;
  text-align: unset;
  transition: background-color 0.3s ease;
}
.EventCard_Card__S7WES::after {
  content: "";
  position: absolute;
  top: -0.25px;
  left: 0;
  bottom: -0.25px;
  width: 4px;
  transition: width 0.3s ease;
  background-color: #0062ff;
}
.EventCard_Card__S7WES:focus {
  border-color: #ffffff;
  outline: none;
}
.EventCard_Card__S7WES:hover {
  background-color: #000000;
}
.EventCard_CardSelected__2JZ9s {
  background-color: #393939;
}
.EventCard_CardSelected__2JZ9s::after {
  width: 8px;
}
.EventCard_CardSelected__2JZ9s:hover {
  background-color: #000000;
}
.EventCard_CardMedium__sehWg {
  color: #f37113;
}
.EventCard_CardMedium__sehWg::after {
  background-color: #f37113;
}
.EventCard_CardHigh__1OGPv {
  color: #da1e28;
}
.EventCard_CardHigh__1OGPv::after {
  background-color: #da1e28;
}

@media screen and (min-width: 1025px) {
  .EventCard_Card__S7WES {
    padding: 10px 10px 10px 24px;
  }
}
.EventSummary_Header__3ddZ2 {
  display: flex;
  flex-flow: column;
  color: #ffffff;
  width: 100%;
}

.EventSummary_TitleGroup__E-asG {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-bottom: 3px;
  align-items: baseline;
}

.EventSummary_TypeGroup__YrfcX {
  flex: 0 1 auto;
  display: flex;
  flex-flow: row wrap;
  align-items: baseline;
}

.EventSummary_InfoGroup__2K9oj {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.EventSummary_Type__3on9c {
  margin: 0 10px 0 0;
  text-transform: capitalize;
}

.EventSummary_Id__33Ah_ {
  margin: 0;
  white-space: nowrap;
}

.EventSummary_StartTime__2pHIj {
  justify-self: flex-end;
  margin: 0;
  white-space: nowrap;
}

.EventSummary_Details__1BeLb {
  margin: 0;
}
.MobileFilters_Wrapper__3WGtf {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex: 0 1 auto;
  background-color: #262626;
}

.MobileFilters_MobileFilterBar__1zNS0 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* stylelint-disable-next-line selector-max-universal */
}
.MobileFilters_MobileFilterBar__1zNS0 > * {
  margin-right: 5px;
  flex-basis: 33.333333%;
}
.MobileFilters_MobileFilterBar__1zNS0 > :first-child {
  margin-left: 5px;
}

.MobileFilters_FilterButton__-zySm {
  transition-property: border-color;
  transition-duration: 120ms;
  transition-timing-function: cubic-bezier(0, 1.08, 1, 1);
  display: inline;
  background-color: #262626;
  border: 1px solid #525252;
}
.MobileFilters_FilterButton__-zySm:hover, .MobileFilters_FilterButton__-zySm:focus {
  background-color: #262626;
  border: 1px solid #8d8d8d;
  outline: none;
}
.MobileFilters_FilterButtonHasActive__3NiJh {
  background-color: #393939;
}
.MobileFilters_FilterButtonHasActive__3NiJh:hover, .MobileFilters_FilterButtonHasActive__3NiJh:focus {
  background-color: #393939;
}

.MobileFilters_FilterButtonContent__2dH-N {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.MobileFilters_FiltersCount__35ieN {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: #ffffff;
  color: #000000;
  margin-left: 5px;
}

.MobileFilters_ResultsCount__2Tv5h {
  display: inline;
  text-align: center;
  max-width: 80px;
  align-self: center;
}

.MobileFilters_Overlay__3JnnF {
  top: 43px;
  padding: 0;
  background-color: #262626;
  /* stylelint-disable-next-line selector-max-universal */
}
.MobileFilters_Overlay__3JnnF > * {
  background-color: transparent;
}

.MobileFilters_ModalControls__3svBy {
  display: flex;
  align-items: center;
  z-index: 10;
  height: 64px;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 20px;
  border-top: 1px solid #525252;
  border-bottom: 1px solid #525252;
}

.MobileFilters_FiltersList__1urjS {
  max-height: calc(100vh - 108px);
  overflow-y: auto;
  background: transparent;
}

.MobileFilters_Filter__1juUo {
  padding: 20px;
  border-bottom: 1px solid #525252;
}

.MobileFilters_Close__1N2Bt {
  color: #ffffff;
  background-color: transparent;
  border: 0;
}
.MobileFilters_Close__1N2Bt:hover {
  cursor: pointer;
}

.MobileFilters_ShowButton__3a486 {
  width: calc(100% - 40px);
  height: 48px;
  margin: 20px;
}

@media screen and (min-width: 375px) {
  .MobileFilters_MobileFilterBar__1zNS0 {
    /* stylelint-disable-next-line selector-max-universal */
  }
  .MobileFilters_MobileFilterBar__1zNS0 > :first-child {
    margin-left: 10px;
  }
  .MobileFilters_MobileFilterBar__1zNS0 > * {
    margin-right: 10px;
  }
}
@media screen and (min-width: 1025px) {
  .MobileFilters_Wrapper__3WGtf {
    display: none;
  }
}
.DesktopFilters_Wrapper__hk-qg {
  flex-direction: row;
  align-items: center;
  padding: 20px;
  display: none;
}

.DesktopFilters_FilterWrapper__913pd {
  margin-right: 12px;
  position: relative;
}

.DesktopFilters_Icon__I1SHB {
  margin-left: 20px;
}

.DesktopFilters_Filter__3LPDY {
  position: absolute;
  background-color: #393939;
  top: 50px;
  z-index: 110;
  padding: 22px 30px;
  display: none;
}
.DesktopFilters_FilterOpen__13ZaD {
  display: block;
  min-width: 278px;
}

.DesktopFilters_Buttons__BYTWg {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 27px;
}

@media screen and (min-width: 1025px) {
  .DesktopFilters_Wrapper__hk-qg {
    display: flex;
    flex: 0 1 auto;
    background-color: #262626;
  }
  .DesktopFilters_Wrapper__hk-qg > :not(:last-child) {
    margin-right: 15px;
  }
  .DesktopFilters_ResultsCount__10msn {
    padding-right: 16px;
    position: relative;
    bottom: -13px;
  }
  .DesktopFilters_ResponsiveGap__XT1I7 {
    flex-grow: 1;
  }
}
@media screen and (min-width: 1025px) {
  .HistoryPage_ListMapWrapper__2YHdI {
    position: relative;
    width: 100%;
    height: 100%;
    flex: 1 1 auto;
    overflow: hidden;
  }
}
.closeButton_Link__A9jeZ::before {
  content: "";
  position: absolute;
  height: 5px;
  width: 100%;
  border-top: 4px solid #0062ff;
  top: 0;
  left: 0;
  right: 0;
  transition: 0.3s ease;
  opacity: 0;
}

.closeButton_Toggle___8_8E {
  z-index: 100;
  display: block;
  position: relative;
  height: auto;
  padding: 16px 10px 27px;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  color: #8a8d91;
  text-align: left;
  text-decoration: none;
  transition: 0.3s ease;
  border-style: none;
  outline: none;
  background-color: #262626;
}
.closeButton_ToggleSelected__1eucS {
  color: #ffffff;
  background-color: #393939;
}
.closeButton_ToggleSelected__1eucS::before {
  opacity: 1;
}
.closeButton_Toggle___8_8E span {
  display: block;
  width: 20px;
  height: 2px;
  margin: 0 auto 3px;
  position: relative;
  background: currentColor;
  z-index: 1;
  transform-origin: 0% 50%;
  transition: transform 0.3s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.3s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.3s ease-in-out;
}
.closeButton_ToggleOpen__1lKiW {
  color: #ffffff;
}
.closeButton_ToggleOpen__1lKiW :nth-last-child(4) {
  transform: translate(0, 1px) rotate(45deg);
}
.closeButton_ToggleOpen__1lKiW :nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}
.closeButton_ToggleOpen__1lKiW :nth-last-child(2) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}
.closeButton_ToggleOpen__1lKiW :last-child {
  opacity: 1;
  transform: translate(0, 0) rotate(-45deg);
}
.closeButton_ToggleOverlay__1skVk {
  display: none;
  z-index: -1;
  opacity: 0;
  border-style: none;
  outline: none;
}
.closeButton_ToggleOverlayOpen__3LeOu {
  z-index: 98;
  display: flex;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #161616;
  opacity: 0.7;
}

@media screen and (min-width: 1025px) {
  .closeButton_Toggle___8_8E {
    display: none;
  }
}
.Sidebar_SidebarWrapper__2rNWO {
  z-index: 100;
  position: fixed;
  background-color: #262626;
  width: 100%;
  height: 62px;
  left: 0;
  right: 0;
  bottom: 0;
}

.Sidebar_Sidebar__AL-j2 {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
  margin: 0;
  background-color: #262626;
}

.Sidebar_Header__3Z3OJ {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  height: auto;
}

.Sidebar_Logo__Wpqcc {
  display: none;
  color: #0062ff;
}

.Sidebar_Menu__2e2A0 {
  flex: 1 0 auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
}
.Sidebar_MenuCollapsible__2o36e {
  z-index: 99;
  background-color: #262626;
  flex: 1 0 auto;
  position: absolute;
  display: flex;
  flex-flow: column;
  bottom: 0;
  left: 0;
  right: 0;
  transition: transform 0.3s ease;
  transform: translate(0, 100%);
}
.Sidebar_MenuCollapsibleOpen__1waKk {
  transform: translate(0, -69px);
}

.Sidebar_Link__VsHMR {
  z-index: 100;
  background-color: #262626;
  display: flex;
  position: relative;
  height: auto;
  padding: 16px 10px 27px;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  color: #8a8d91;
  text-align: left;
  text-decoration: none;
  transition: 0.3s ease;
}
.Sidebar_Link__VsHMR::before {
  content: "";
  position: absolute;
  height: 5px;
  width: 100%;
  border-top: 4px solid #0062ff;
  top: 0;
  left: 0;
  right: 0;
  transition: 0.3s ease;
  opacity: 0;
}
.Sidebar_LinkIcon__3FkWP {
  position: relative;
}
.Sidebar_LinkIconCount__2N3VX {
  position: absolute;
  top: -12px;
  right: -27px;
  border: solid 3px #262626;
}
.Sidebar_LinkSelected__2TNEi {
  color: #ffffff;
  background-color: #393939;
}
.Sidebar_LinkSelected__2TNEi .Sidebar_LinkIconCount__2N3VX {
  border-color: #393939;
}
.Sidebar_LinkSelected__2TNEi::before {
  opacity: 1;
}

.Sidebar_LinkText__1x5eJ {
  display: none;
}

.Sidebar_Footer__1Hcxr {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 200px;
  overflow-x: hidden;
  border-bottom: solid 0.5px #6f6f6f;
}
.Sidebar_FooterAccount__2321J {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  height: 85px;
  overflow-x: hidden;
  padding: 26px;
}
.Sidebar_FooterAccount__2321J > .Sidebar_LinkIcon__3FkWP {
  min-width: 42px;
  margin-right: 26px;
}
.Sidebar_FooterSupport__Bggwa {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  border-top: solid 0.5px #393939;
  height: 115px;
  overflow-x: hidden;
  padding: 21px 16px 26px 26px;
}
.Sidebar_FooterSupport__Bggwa > .Sidebar_LinkIcon__3FkWP {
  min-width: 42px;
  margin-right: 26px;
}

.Sidebar_Contact__2tUbI {
  color: #a8a8a8;
}

@media screen and (min-width: 1025px) {
  .Sidebar_SidebarWrapper__2rNWO {
    position: relative;
    background-color: #262626;
    width: 78px;
    left: 0;
    bottom: 0;
    height: 100vh;
  }
  .Sidebar_Sidebar__AL-j2 {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 100vh;
    width: 78px;
    overflow-x: hidden;
    z-index: 100;
    background-color: #262626;
    transition: width 0.3s ease-in-out;
    border-right: #525252 1px solid;
  }
  .Sidebar_Sidebar__AL-j2:hover {
    transition-delay: 0.25s;
    width: 260px;
  }
  .Sidebar_Header__3Z3OJ {
    position: relative;
    width: 100%;
    height: 70px;
    padding: 18px 0 26px 26px;
    align-items: center;
    justify-content: flex-start;
  }
  .Sidebar_Header__3Z3OJ::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 15px;
    top: 0;
    right: 0%;
    bottom: 0;
    background-color: #262626;
  }
  .Sidebar_Logo__Wpqcc {
    display: flex;
  }
  .Sidebar_Menu__2e2A0 {
    position: relative;
    flex: 0 0 auto;
    flex-flow: column;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .Sidebar_MenuCollapsible__2o36e {
    background-color: transparent;
    position: relative;
    display: flex;
    flex: 1 0 auto;
    flex-flow: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    top: 0;
    transform: translate(0, 0);
  }
  .Sidebar_MenuSecondary__e_IRa {
    display: flex;
    flex: 1 0 auto;
    flex-flow: column;
    width: 260px;
  }
  .Sidebar_Link__VsHMR {
    display: flex;
    position: relative;
    width: 260px;
    height: 54px;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 0 0 auto;
    padding: 16px 16px 16px 26px;
    color: #8d8d8d;
    text-align: left;
    text-decoration: none;
    transition: 0.3s ease;
  }
  .Sidebar_Link__VsHMR::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 5px;
    border-top-style: none;
    border-left: 4px solid #0062ff;
    top: 0;
    left: 0;
    bottom: 0;
    transition: 0.3s ease;
    opacity: 0;
  }
  .Sidebar_LinkIcon__3FkWP {
    min-width: 42px;
    margin-right: 26px;
  }
  .Sidebar_LinkIconCount__2N3VX {
    top: -16px;
    right: -6px;
  }
  .Sidebar_LinkSelected__2TNEi {
    color: #ffffff;
    background-color: #393939;
  }
  .Sidebar_LinkSelected__2TNEi::before {
    opacity: 1;
  }
  .Sidebar_LinkText__1x5eJ {
    display: flex;
  }
  .Sidebar_Footer__1Hcxr {
    border-bottom-style: none;
  }
  .Sidebar_FooterAccount__2321J {
    width: 260px;
  }
  .Sidebar_FooterSupport__Bggwa {
    width: 260px;
  }
}
.PageLayout_Page__2iNe3 {
  position: relative;
  display: flex;
  background-color: #161616;
}

.PageLayout_Screen__2_-kj {
  display: flex;
  min-height: 100vh;
  flex-grow: 1;
}
@charset "UTF-8";
.PigUpdates_LogEntry__2i52l {
  position: relative;
  padding: 20px 20px 15px 65px;
  background: transparent;
  color: #ffffff;
  text-align: left;
  cursor: pointer;
}
@media screen and (min-width: 1025px) {
  .PigUpdates_LogEntry__2i52l {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
.PigUpdates_LogEntry__2i52l::before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 29px;
  top: 0;
  right: 0;
  bottom: 0;
  width: 1px;
  background-color: #393939;
}
.PigUpdates_LogEntry__2i52l:first-child::before {
  top: 20px;
}
.PigUpdates_LogEntry__2i52l:last-child::before {
  bottom: 40px;
}
.PigUpdates_LogEntry__2i52l:only-child::before {
  display: none;
}
.PigUpdates_LogEntry__2i52l:disabled {
  cursor: initial;
}
.PigUpdates_LogEntry__2i52l:hover {
  background-color: rgba(57, 57, 57, 0.7);
}
.PigUpdates_LogEntryType__niio7 {
  margin-left: 10px;
}
.PigUpdates_LogEntryIcon__1Isnm {
  position: absolute;
  top: 20px;
  left: 24px;
  width: 10px;
  height: 10px;
  background-color: #8d8d8d;
  border: solid 2px #393939;
  border-radius: 50%;
  color: #ffffff;
}
.PigUpdates_LogEntryIconLow__154PN {
  background-color: #0062ff;
}
.PigUpdates_LogEntryIconMedium__2oBOi {
  background-color: #f37113;
}
.PigUpdates_LogEntryIconHigh__SJ3B6 {
  background-color: #da1e28;
}
.PigUpdates_LogEntryWithIcon__1mFg8 {
  display: flex;
  padding-left: 50px;
  flex-wrap: wrap;
}
.PigUpdates_LogEntrySelected__1SaYl {
  background-color: #262626;
}

.PigUpdates_PigIcon__hsRDw {
  position: absolute;
  left: 18px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4px;
}
.PigUpdates_PigIconLow__1IDqU {
  background-color: #0062ff;
}
.PigUpdates_PigIconMedium__J5fQ6 {
  background-color: #f37113;
}
.PigUpdates_PigIconHigh__1oA_a {
  background-color: #da1e28;
}
.PigUpdates_PigIconLowPulse__5oYpx {
  background-color: #0062ff;
  box-shadow: 0 0 0 0 #0062ff;
  animation: PigUpdates_livePulseLow__kThoX 2.5s infinite;
}
.PigUpdates_PigIconMediumPulse__2Pjbw {
  background-color: #f37113;
  box-shadow: 0 0 0 0 #f37113;
  animation: PigUpdates_livePulseMedium__3gtb9 2.5s infinite;
}
.PigUpdates_PigIconHighPulse__2u4x4 {
  background-color: #da1e28;
  box-shadow: 0 0 0 0 #da1e28;
  animation: PigUpdates_livePulseHigh__2MbJ1 2.5s infinite;
}

@keyframes PigUpdates_livePulseLow__kThoX {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 98, 255, 0.5);
  }
  85% {
    box-shadow: 0 0 0 12px rgba(0, 98, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 24px transparent;
  }
}
@keyframes PigUpdates_livePulseMedium__3gtb9 {
  0% {
    box-shadow: 0 0 0 0 rgba(243, 113, 19, 0.5);
  }
  85% {
    box-shadow: 0 0 0 12px rgba(243, 113, 19, 0);
  }
  100% {
    box-shadow: 0 0 0 24px transparent;
  }
}
@keyframes PigUpdates_livePulseHigh__2MbJ1 {
  0% {
    box-shadow: 0 0 0 0 rgba(218, 30, 40, 0.5);
  }
  85% {
    box-shadow: 0 0 0 12px rgba(218, 30, 40, 0);
  }
  100% {
    box-shadow: 0 0 0 24px transparent;
  }
}
.PigUpdates_Text__12e0s {
  margin: 0;
  text-transform: capitalize;
}

.PigUpdates_LogCollapsed__3aDLT {
  position: relative;
  margin: 5px 0 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 65px;
}
.PigUpdates_LogCollapsed__3aDLT::before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 29px;
  top: -5px;
  width: 1px;
  height: 5px;
  background-color: #393939;
}
.PigUpdates_LogCollapsed__3aDLT::after {
  content: "\2022\2022\2022\2022";
  position: absolute;
  transform: rotate(90deg);
  transform-origin: top left;
  z-index: 0;
  left: 39px;
  top: 5px;
  width: 1px;
  letter-spacing: 3px;
  font-size: 13px;
  color: #393939;
}
.PigUpdates_LogCollapsed__3aDLT + .PigUpdates_LogEntry__2i52l::before {
  top: -5px;
}

.PigUpdates_ToggleCollapsedButton__17CWu,
.PigUpdates_ViewLessButton__2Yxdz {
  color: #408bfd;
  padding: 0;
  line-height: 20px;
}

.PigUpdates_ViewLessButton__2Yxdz {
  text-align: left;
  display: block;
  margin: 20px 0 20px 65px;
}
.PigUpdates_ViewLessButton__2Yxdz + .PigUpdates_LogEntry__2i52l::before {
  top: -60px;
}

.PigUpdates_LogList__3z4-X {
  position: relative;
  display: flex;
  flex-flow: column;
  padding-bottom: 30px;
}

.PigUpdates_SmallText__Ub7r_ {
  display: block;
  width: 100%;
  color: #8d8d8d;
}
.RunNotes_LogForm__1w89X {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 25px;
}

.RunNotes_InputWrapper__fPSUF {
  flex-grow: 1;
}

.RunNotes_SendButton__3cwnN {
  margin-left: 10px;
}

.RunNotes_LogInputText__2qHjq {
  margin: 20px 0 0;
}

.RunNotes_EmptyText__2cbcZ {
  padding: 10px;
  background-color: #262626;
  display: block;
  margin-top: 23px;
}

.RunNotes_ToggleButton__27729 {
  border: 0;
  background: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  color: #ffffff;
  cursor: pointer;
}

.RunNotes_ButtonText__30_ds {
  display: block;
  text-align: left;
}

.RunNotes_ToggleIcon__xIDSz {
  display: flex;
  margin-left: 10px;
  transition: transform 0.3s ease-in-out;
  width: 20px;
  height: 20px;
}
.RunNotes_ToggleIconOpen__w7JmQ {
  transform: rotateX(180deg);
}

.RunNotes_Notes__18U1n {
  visibility: hidden;
  max-height: 0;
  overflow: hidden;
  text-align: left;
}
.RunNotes_NotesOpen__PPjfa {
  visibility: visible;
  max-height: 100%;
  overflow-y: auto;
}
.PigSummary_Wrapper__2fsGX {
  padding: 30px 20px;
  border-top: 1px solid #393939;
}
.PigSummary_Wrapper__2fsGX:first-child {
  border-top: 0;
}
.PigSummary_Wrapper__2fsGX:last-child {
  flex-grow: 1;
}
@media screen and (min-width: 1025px) {
  .PigSummary_Wrapper__2fsGX:last-child {
    flex-grow: initial;
  }
}

.PigSummary_Header__2TU-_ {
  display: flex;
  flex-flow: column;
  color: #ffffff;
  width: 100%;
}

.PigSummary_TypeGroup__1fdrA {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 20px;
  text-transform: capitalize;
}

.PigSummary_Title__34yWo {
  margin-bottom: 20px;
}
.PigSummary_TitleUpdates__2IT65 {
  margin-bottom: 0;
}

.PigSummary_Text__1WXmb {
  margin: 0;
}

.PigSummary_Label__sCRFt {
  width: 30%;
  margin: 0;
}

.PigSummary_SmallText__3qg1_ {
  margin: 0;
  color: #6f6f6f;
}

.PigSummary_SelectGroup__3yXqu {
  align-items: center;
}

.PigSummary_Notes__31NUP {
  margin-top: 20px;
}
.PigInfo_Wrapper__3hW5t {
  flex: 1 0 350px;
  display: flex;
  flex-flow: column;
  padding: 20px;
}

.PigInfo_Row__212P_ {
  display: grid;
  grid-template-columns: 130px 1fr;
  grid-template-rows: auto;
}
.PigInfo_Row__212P_:not(:last-child) {
  margin-bottom: 5px;
}

.PigInfo_Text__3tg0a {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.PigInfo_TextPoi__10Gyn {
  text-transform: capitalize;
}

.PigInfo_Link__fLn7h {
  text-decoration: underline;
  text-underline-position: under;
}

.PigInfo_Icon___GsNo {
  margin-left: 16px;
}

.PigInfo_Divider__2QvOd {
  width: 100%;
  border: 0;
  border-top: 1px solid #393939;
  margin: 15px 0 20px;
}

@media screen and (min-width: 768px) {
  .PigInfo_Wrapper__3hW5t {
    padding: 20px 30px 20px 60px;
    background-color: #262626;
  }
}
.Header_Header__1e6eW {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-bottom: solid 1px #393939;
}

.Header_Icon__1gSGX {
  display: flex;
}

.Header_Type__3JcIi {
  margin: 0 0 0 10px;
  text-transform: capitalize;
  color: #ffffff;
}

.Header_Id___ofx7 {
  margin: 0 0 0 34px;
  color: #ffffff;
}

.Header_Button__WpKsz:not(:last-child) {
  margin-right: 10px;
}
.Header_ButtonGroup__QLExF {
  display: flex;
  flex-flow: row nowrap;
  padding: 0 0 10px;
}
.Header_ButtonShare__3TPVh {
  display: none;
}

.Header_TitleWrapper__FGx7f {
  display: flex;
  flex-wrap: wrap;
}
.Header_TitleWrapper__FGx7f .Header_Type__3JcIi,
.Header_TitleWrapper__FGx7f .Header_Id___ofx7 {
  align-self: baseline;
}

@media screen and (min-width: 1025px) {
  .Header_Header__1e6eW {
    display: flex;
    padding: 10px 0;
  }
  .Header_EventId__3Q-Qt {
    margin-left: 10px;
  }
  .Header_TitleGroup__1O63M {
    display: flex;
    flex: 1 0 auto;
    align-items: center;
  }
  .Header_Icon__1gSGX {
    margin-left: 5px;
  }
  .Header_Id___ofx7 {
    margin-left: 10px;
    display: inline;
  }
  .Header_ButtonGroup__QLExF {
    padding: 0;
  }
  .Header_ButtonShare__3TPVh {
    display: flex;
  }
  .Header_Type__3JcIi {
    margin-left: 10px;
  }
}
.PigUpdateDetails_Content__SzoOk {
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  overflow-y: none;
}

.PigUpdateDetails_Fixed__1ebZm {
  width: 100%;
  height: auto;
}

.PigUpdateDetails_Scroll__3q_5t {
  position: relative;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-flow: column;
  overflow-y: auto;
  padding-bottom: 10px;
}

.PigUpdateDetails_MapWrapper__2YavW {
  min-height: 450px;
  width: 100%;
  background-color: #262626;
}

.PigUpdateDetails_SchematicMapWrapper__3F-sF {
  height: 206px;
  width: 100%;
  background-color: #262626;
}
.PigDetailsSection_Container__1nEUP {
  position: relative;
  width: 100%;
}
@media screen and (min-width: 1025px) {
  .PigDetailsSection_Container__1nEUP {
    height: 100%;
    overflow-y: auto;
  }
}
.PigDetailsSection_ContainerLoading__lVHju {
  /* stylelint-disable function-calc-no-invalid */
  max-height: calc(100% - 130px);
  /* stylelint-enable function-calc-no-invalid */
  display: flex;
  justify-content: center;
  align-items: center;
}

.PigDetailsSection_ListMapWrapper__2fccE {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  height: 100%;
}

.PigDetailsSection_List__3K8Ly {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-style: none;
}

.PigDetailsSection_MapModalWrapper__25awB {
  position: absolute;
  background-color: #393939;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.PigDetailsSection_MapContainer__2qelL {
  display: none;
}

@media screen and (min-width: 1025px) {
  .PigDetailsSection_HeaderWrapper__2x2gJ {
    border-top: solid 0.25px #393939;
    border-bottom: solid 0.25px #393939;
  }
  .PigDetailsSection_ListMapWrapper__2fccE {
    overflow: hidden;
  }
  .PigDetailsSection_List__3K8Ly {
    display: block;
    flex: 1 0 25%;
    overflow-y: auto;
    margin: 0;
    min-width: 370px;
    max-width: 600px;
    border-right: solid 0.25px #393939;
  }
  .PigDetailsSection_MapModalWrapper__25awB {
    position: relative;
  }
  .PigDetailsSection_MapContainer__2qelL {
    display: flex;
    z-index: 1;
    position: relative;
    background-color: #393939;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}
.PigDetailsPage_ListMapWrapper__2xt0- {
  position: relative;
  width: 100%;
  height: 100%;
  flex: 1 1 auto;
  overflow: hidden;
}

.PigDetailsPage_HeaderWrapper__2ze2U {
  width: 100%;
  height: auto;
  border: 0;
}
@media screen and (min-width: 1025px) {
  .PigDetailsPage_HeaderWrapper__2ze2U {
    border-top: 1px solid #393939;
    border-bottom: 1px solid #393939;
  }
}
@charset "UTF-8";
.TrainUpdates_LogEntry__20LKv {
  position: relative;
  padding: 20px 20px 15px 65px;
  background: transparent;
  color: #ffffff;
  text-align: left;
  cursor: pointer;
}
@media screen and (min-width: 1025px) {
  .TrainUpdates_LogEntry__20LKv {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
.TrainUpdates_LogEntry__20LKv::before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 29px;
  top: 0;
  right: 0;
  bottom: 0;
  width: 1px;
  background-color: #393939;
}
.TrainUpdates_LogEntry__20LKv:first-child::before {
  top: 20px;
}
.TrainUpdates_LogEntry__20LKv:last-child::before {
  bottom: 40px;
}
.TrainUpdates_LogEntry__20LKv:only-child::before {
  display: none;
}
.TrainUpdates_LogEntry__20LKv:disabled {
  cursor: initial;
}
.TrainUpdates_LogEntry__20LKv:hover {
  background-color: rgba(57, 57, 57, 0.7);
}
.TrainUpdates_LogEntryType__3hnrW {
  margin-left: 10px;
}
.TrainUpdates_LogEntryIcon__3AiSH {
  position: absolute;
  top: 20px;
  left: 24px;
  width: 10px;
  height: 10px;
  background-color: #8d8d8d;
  border: solid 2px #393939;
  border-radius: 50%;
  color: #ffffff;
}
.TrainUpdates_LogEntryIconLow__34je5 {
  background-color: #0062ff;
}
.TrainUpdates_LogEntryIconMedium__Yaa_L {
  background-color: #f37113;
}
.TrainUpdates_LogEntryIconHigh__3vyZA {
  background-color: #da1e28;
}
.TrainUpdates_LogEntryWithIcon__KW-_j {
  display: flex;
  padding-left: 50px;
  flex-wrap: wrap;
}
.TrainUpdates_LogEntrySelected__13xwm {
  background-color: #262626;
}

.TrainUpdates_TrainIcon__3C4a0 {
  position: absolute;
  left: 18px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4px;
}
.TrainUpdates_TrainIconLow__2vTLF {
  background-color: #0062ff;
}
.TrainUpdates_TrainIconMedium__1P1ab {
  background-color: #f37113;
}
.TrainUpdates_TrainIconHigh__2ychg {
  background-color: #da1e28;
}
.TrainUpdates_TrainIconLowPulse__1A0Dq {
  background-color: #0062ff;
  box-shadow: 0 0 0 0 #0062ff;
  animation: TrainUpdates_livePulseLow__1kBLN 2.5s infinite;
}
.TrainUpdates_TrainIconMediumPulse__15ub_ {
  background-color: #f37113;
  box-shadow: 0 0 0 0 #f37113;
  animation: TrainUpdates_livePulseMedium__3Idwh 2.5s infinite;
}
.TrainUpdates_TrainIconHighPulse__1SeIR {
  background-color: #da1e28;
  box-shadow: 0 0 0 0 #da1e28;
  animation: TrainUpdates_livePulseHigh__2xtWP 2.5s infinite;
}

@keyframes TrainUpdates_livePulseLow__1kBLN {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 98, 255, 0.5);
  }
  85% {
    box-shadow: 0 0 0 12px rgba(0, 98, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 24px transparent;
  }
}
@keyframes TrainUpdates_livePulseMedium__3Idwh {
  0% {
    box-shadow: 0 0 0 0 rgba(243, 113, 19, 0.5);
  }
  85% {
    box-shadow: 0 0 0 12px rgba(243, 113, 19, 0);
  }
  100% {
    box-shadow: 0 0 0 24px transparent;
  }
}
@keyframes TrainUpdates_livePulseHigh__2xtWP {
  0% {
    box-shadow: 0 0 0 0 rgba(218, 30, 40, 0.5);
  }
  85% {
    box-shadow: 0 0 0 12px rgba(218, 30, 40, 0);
  }
  100% {
    box-shadow: 0 0 0 24px transparent;
  }
}
.TrainUpdates_Text__VtH4l {
  margin: 0;
  text-transform: capitalize;
}

.TrainUpdates_LogCollapsed__31397 {
  position: relative;
  margin: 5px 0 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 65px;
}
.TrainUpdates_LogCollapsed__31397::before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 29px;
  top: -5px;
  width: 1px;
  height: 5px;
  background-color: #393939;
}
.TrainUpdates_LogCollapsed__31397::after {
  content: "\2022\2022\2022\2022";
  position: absolute;
  transform: rotate(90deg);
  transform-origin: top left;
  z-index: 0;
  left: 39px;
  top: 5px;
  width: 1px;
  letter-spacing: 3px;
  font-size: 13px;
  color: #393939;
}
.TrainUpdates_LogCollapsed__31397 + .TrainUpdates_LogEntry__20LKv::before {
  top: -5px;
}

.TrainUpdates_ToggleCollapsedButton__2FNno,
.TrainUpdates_ViewLessButton__3j_U3 {
  color: #408bfd;
  padding: 0;
  line-height: 20px;
}

.TrainUpdates_ViewLessButton__3j_U3 {
  text-align: left;
  display: block;
  margin: 20px 0 20px 65px;
}
.TrainUpdates_ViewLessButton__3j_U3 + .TrainUpdates_LogEntry__20LKv::before {
  top: -60px;
}

.TrainUpdates_LogList__2hmT3 {
  position: relative;
  display: flex;
  flex-flow: column;
  padding-bottom: 30px;
}

.TrainUpdates_SmallText__NnnpQ {
  display: block;
  width: 100%;
  color: #8d8d8d;
}
.RunNotes_LogForm__39elT {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 25px;
}

.RunNotes_InputWrapper__3MD7U {
  flex-grow: 1;
}

.RunNotes_SendButton__1v6SE {
  margin-left: 10px;
}

.RunNotes_LogInputText__1C-2L {
  margin: 20px 0 0;
}

.RunNotes_EmptyText__Pdlmh {
  padding: 10px;
  background-color: #262626;
  display: block;
  margin-top: 23px;
}

.RunNotes_ToggleButton__1Hg9H {
  border: 0;
  background: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  color: #ffffff;
  cursor: pointer;
}

.RunNotes_ButtonText__MQ1Rc {
  display: block;
  text-align: left;
}

.RunNotes_ToggleIcon__2LLlX {
  display: flex;
  margin-left: 10px;
  transition: transform 0.3s ease-in-out;
  width: 20px;
  height: 20px;
}
.RunNotes_ToggleIconOpen__1UJNW {
  transform: rotateX(180deg);
}

.RunNotes_Notes__85D0H {
  visibility: hidden;
  max-height: 0;
  overflow: hidden;
  text-align: left;
}
.RunNotes_NotesOpen__2iTiE {
  visibility: visible;
  max-height: 100%;
  overflow-y: auto;
}
.TrainSummary_Wrapper__1in8Y {
  padding: 30px 20px;
  border-top: 1px solid #393939;
}
.TrainSummary_Wrapper__1in8Y:first-child {
  border-top: 0;
}
.TrainSummary_Wrapper__1in8Y:last-child {
  flex-grow: 1;
}
@media screen and (min-width: 1025px) {
  .TrainSummary_Wrapper__1in8Y:last-child {
    flex-grow: initial;
  }
}

.TrainSummary_Header__1hoH9 {
  display: flex;
  flex-flow: column;
  color: #ffffff;
  width: 100%;
}

.TrainSummary_TypeGroup__2QnUe {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 20px;
  text-transform: capitalize;
}

.TrainSummary_Title__3LEjG {
  margin-bottom: 20px;
}
.TrainSummary_TitleUpdates__2bFzA {
  margin-bottom: 0;
}

.TrainSummary_Text__ajp2Z {
  margin: 0;
}

.TrainSummary_Label__3N5Ho {
  width: 30%;
  margin: 0;
}

.TrainSummary_SmallText__181v_ {
  margin: 0;
  color: #6f6f6f;
}

.TrainSummary_SelectGroup__1Z132 {
  align-items: center;
}

.TrainSummary_Notes__koPJL {
  margin-top: 20px;
}
.TrainInfo_Wrapper__2aAQt {
  flex: 1 0 350px;
  display: flex;
  flex-flow: column;
  padding: 20px;
}

.TrainInfo_Row__1UfI9 {
  display: grid;
  grid-template-columns: 130px 1fr;
  grid-template-rows: auto;
}
.TrainInfo_Row__1UfI9:not(:last-child) {
  margin-bottom: 5px;
}

.TrainInfo_Text__jZABV {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.TrainInfo_TextPoi__2aGs9 {
  text-transform: capitalize;
}

.TrainInfo_Link__1-19o {
  text-decoration: underline;
  text-underline-position: under;
}

.TrainInfo_Icon__2A_9z {
  margin-left: 16px;
}

.TrainInfo_Divider__bVJrT {
  width: 100%;
  border: 0;
  border-top: 1px solid #393939;
  margin: 15px 0 20px;
}

@media screen and (min-width: 768px) {
  .TrainInfo_Wrapper__2aAQt {
    padding: 20px 30px 20px 60px;
    background-color: #262626;
  }
}
.Header_Header__1M55_ {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-bottom: solid 1px #393939;
}

.Header_Icon__2a_KO {
  display: flex;
}

.Header_Type__2ArZf {
  margin: 0 0 0 10px;
  text-transform: capitalize;
  color: #ffffff;
}

.Header_Id__wOxay {
  margin: 0 0 0 34px;
  color: #ffffff;
}

.Header_Button__1lnua:not(:last-child) {
  margin-right: 10px;
}
.Header_ButtonGroup__3Y_rU {
  display: flex;
  flex-flow: row nowrap;
  padding: 0 0 10px;
}
.Header_ButtonShare__1hc6w {
  display: none;
}

.Header_TitleWrapper__1CT6X {
  display: flex;
  flex-wrap: wrap;
}
.Header_TitleWrapper__1CT6X .Header_Type__2ArZf,
.Header_TitleWrapper__1CT6X .Header_Id__wOxay {
  align-self: baseline;
}

@media screen and (min-width: 1025px) {
  .Header_Header__1M55_ {
    display: flex;
    padding: 10px 0;
  }
  .Header_EventId__jk2nt {
    margin-left: 10px;
  }
  .Header_TitleGroup__1uVDd {
    display: flex;
    flex: 1 0 auto;
    align-items: center;
  }
  .Header_Icon__2a_KO {
    margin-left: 5px;
  }
  .Header_Id__wOxay {
    margin-left: 10px;
    display: inline;
  }
  .Header_ButtonGroup__3Y_rU {
    padding: 0;
  }
  .Header_ButtonShare__1hc6w {
    display: flex;
  }
  .Header_Type__2ArZf {
    margin-left: 10px;
  }
}
.TrainUpdateDetails_Content__1Ci5C {
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  overflow-y: none;
}

.TrainUpdateDetails_Fixed__5zfJf {
  width: 100%;
  height: auto;
}

.TrainUpdateDetails_Scroll__JUhyd {
  position: relative;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-flow: column;
  overflow-y: auto;
  padding-bottom: 10px;
}

.TrainUpdateDetails_MapWrapper__3G-RB {
  min-height: 450px;
  width: 100%;
  background-color: #262626;
}

.TrainUpdateDetails_SchematicMapWrapper__Nie_S {
  height: 206px;
  width: 100%;
  background-color: #262626;
}
.TrainDetailsSection_Container__16-QA {
  position: relative;
  width: 100%;
}
@media screen and (min-width: 1025px) {
  .TrainDetailsSection_Container__16-QA {
    height: 100%;
    overflow-y: auto;
  }
}
.TrainDetailsSection_ContainerLoading__fOkAn {
  /* stylelint-disable function-calc-no-invalid */
  max-height: calc(100% - 130px);
  /* stylelint-enable function-calc-no-invalid */
  display: flex;
  justify-content: center;
  align-items: center;
}

.TrainDetailsSection_ListMapWrapper__1WBq9 {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  height: 100%;
}

.TrainDetailsSection_List__30bWY {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-style: none;
}

.TrainDetailsSection_MapModalWrapper__1qs_n {
  position: absolute;
  background-color: #393939;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.TrainDetailsSection_MapContainer__29tFE {
  display: none;
}

@media screen and (min-width: 1025px) {
  .TrainDetailsSection_HeaderWrapper__11wLF {
    border-top: solid 0.25px #393939;
    border-bottom: solid 0.25px #393939;
  }
  .TrainDetailsSection_ListMapWrapper__1WBq9 {
    overflow: hidden;
  }
  .TrainDetailsSection_List__30bWY {
    display: block;
    flex: 1 0 25%;
    overflow-y: auto;
    margin: 0;
    min-width: 370px;
    max-width: 600px;
    border-right: solid 0.25px #393939;
  }
  .TrainDetailsSection_MapModalWrapper__1qs_n {
    position: relative;
  }
  .TrainDetailsSection_MapContainer__29tFE {
    display: flex;
    z-index: 1;
    position: relative;
    background-color: #393939;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}
.TrainDetailsPage_ListMapWrapper__SrL4N {
  position: relative;
  width: 100%;
  height: 100%;
  flex: 1 1 auto;
  overflow: hidden;
}

.TrainDetailsPage_HeaderWrapper__lhFk4 {
  width: 100%;
  height: auto;
  border: 0;
}
@media screen and (min-width: 1025px) {
  .TrainDetailsPage_HeaderWrapper__lhFk4 {
    border-top: 1px solid #393939;
    border-bottom: 1px solid #393939;
  }
}
/* stylelint-disable */
html {
  font-size: 16px;
}

html * {
  font-family: "Inter", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  color: #ffffff;
  background-color: #161616;
}

.mainContainer {
  min-height: 100vh;
  margin-left: 18.75vw;
  background-color: #161616;
  padding: 2rem 3rem;
}

a {
  color: #ffffff;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
}

fieldset {
  border: 0;
  padding: 0;
  margin: 0;
  min-width: 0;
}

button {
  border: 0;
  padding: 0;
  margin: 0;
  min-width: 0;
}
