@import "../../../styles/colors";
@import "../../../styles/variables";

.PipelineLegend {
  display: none;
}

.Pipeline {
  display: flex;
  color: $white;
  // flex-flow: column;
  background-color: transparent;
  width: 100%;
  vertical-align: middle;
  height: 30px;
  padding: 0 18px;


  &:hover {
    background-color: $black;
  }

  &Focused {
    background-color: $mine-shaft;
    border-left: 0.3em solid $blue-ribbon;
    &:hover {
      background-color: $black;
    }
  }
}



@include breakpoint(large) {
  .PipelineLegend {
    display: flex;
    flex-flow: column;
    max-height: 400px;
    overflow-y: auto;

    &Row {
      display: flex;
      flex-flow: row;
      align-items: center;
      height: 30px;
    }

  }
  .PipelineColor {
    stroke-width: 2;
    align-self: center;
    margin-right: 10px;
  }
  .PipelineName {
    align-self: center;
  }
}
